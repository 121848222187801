/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  HttpStatusCode,
  stringifyWithdrawUri,
  WithdrawUriResult,
} from "@gnu-taler/taler-util";
import {
  Button,
  LocalNotificationBanner,
  useLocalNotificationHandler,
  useTalerWalletIntegrationAPI,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useEffect } from "preact/hooks";
import { QR } from "../components/QR.js";
import { useBankCoreApiContext } from "@gnu-taler/web-util/browser";
import { useSessionState } from "../hooks/session.js";

const TALER_SCREEN_ID = 109;

export function QrCodeSection({
  withdrawUri,
  onAborted,
}: {
  withdrawUri: WithdrawUriResult;
  onAborted: () => void;
}): VNode {
  const { i18n } = useTranslationContext();
  const walletInegrationApi = useTalerWalletIntegrationAPI();
  const talerWithdrawUri = stringifyWithdrawUri(withdrawUri);
  const { state: credentials } = useSessionState();
  const creds = credentials.status !== "loggedIn" ? undefined : credentials;

  useEffect(() => {
    walletInegrationApi.publishTalerAction(withdrawUri);
  }, []);

  const [notification, handleError] = useLocalNotificationHandler();

  const {
    lib: { bank: api },
  } = useBankCoreApiContext();

  const onAbortHandler = handleError(
    async () => {
      if (!creds) return undefined;
      return api.abortWithdrawalById(creds, withdrawUri.withdrawalOperationId);
    },
    onAborted,
    (fail) => {
      switch (fail.case) {
        case HttpStatusCode.BadRequest:
          return i18n.str`The operation ID is invalid.`;
        case HttpStatusCode.NotFound:
          return i18n.str`The operation was not found.`;
        case HttpStatusCode.Conflict:
          return i18n.str`The reserve operation has been confirmed previously and can't be aborted`;
      }
    },
  );

  return (
    <Fragment>
      <LocalNotificationBanner notification={notification} />

      <div class="bg-white shadow-xl sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            <i18n.Translate>
              If you have a Taler wallet installed on this device
            </i18n.Translate>
          </h3>
          <div class="mt-4 mb-4 text-sm text-gray-500">
            <p>
              <i18n.Translate>
                Your wallet will display the details of the transaction
                including the fees (if applicable). If you do not yet have a
                wallet, please follow the instructions
              </i18n.Translate>{" "}
              <a
                class="font-semibold text-indigo-600 hover:text-indigo-900"
                name="wallet page"
                href="https://taler.net/en/wallet.html"
              >
                <i18n.Translate>on this page</i18n.Translate>
              </a>
              .
            </p>
          </div>
          <div class="flex items-center justify-between gap-x-6  pt-2 mt-2 ">
            <Button
              type="button"
              name="cancel"
              class="text-sm font-semibold leading-6 text-gray-900"
              handler={onAbortHandler}
            >
              <i18n.Translate>Cancel</i18n.Translate>
            </Button>
            <a
              href={talerWithdrawUri}
              name="withdraw"
              class="inline-flex items-center  disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <i18n.Translate>Withdraw</i18n.Translate>
            </a>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-xl sm:rounded-lg mt-8">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            <i18n.Translate>
              In case you have a Taler wallet on another device
            </i18n.Translate>
          </h3>
          <div class="mt-4 max-w-xl text-sm text-gray-500">
            <i18n.Translate>
              Scan the QR code below to start the withdrawal.
            </i18n.Translate>
          </div>
          <div class="mt-2 max-w-md ml-auto mr-auto">
            <QR text={talerWithdrawUri} />
          </div>
        </div>
        <div class="flex items-center justify-center gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <Button
            type="button"
            // class="disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md  px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            class="text-sm font-semibold leading-6 text-gray-900"
            handler={onAbortHandler}
          >
            <i18n.Translate>Cancel</i18n.Translate>
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
