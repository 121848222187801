/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { App } from "./app.js";
import { h, render, VNode } from "preact";
import "./scss/main.css";

function getState(node: VNode) {
  const component = node.type as any;
  return { key: node.key, props: node.props, screen: component.SCREEN_ID };
}

const element = document.getElementById("app");
if (element) {
  const vnode = <App />;
  // @ts-expect-error unknown var
  window.showPreactState = () => {
    console.log(JSON.stringify(getState(vnode), undefined, 2));
  };
  render(vnode, element);
} else {
  console.error("HTML element with id 'app' not found.");
}
