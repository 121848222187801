
export interface StringsType {
  domain: string;
  lang: string;
  completeness: number;
  'plural_forms': string;
  locale_data: {
    messages: Record<string, unknown>;
  };
};
export const strings: Record<string,StringsType> = {};

strings['uk'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=3; plural=n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2;",
        "lang": "uk"
      },
      "Show withdrawal confirmation": [
        "Показати підтвердження зняття коштів"
      ],
      "Withdraw without setting amount": [
        ""
      ],
      "Show copy account letter": [
        ""
      ],
      "Show demo description": [
        "Показати демо опис"
      ],
      "Show install wallet first": [
        "Спочатку показати, як встановити гаманець"
      ],
      "Remove password length validation on registration": [
        ""
      ],
      "Show debug info": [
        "Показати інформацію для відладки"
      ],
      "Operation failed, please report": [
        "Операція не вдалася, будь ласка, повідомте про це"
      ],
      "Request timeout": [
        "Тайм-аут запиту"
      ],
      "Request throttled": [
        "Запит затримується"
      ],
      "Malformed response": [
        "Некоректна відповідь"
      ],
      "Network error": [
        "Мережева помилка"
      ],
      "Unexpected request error": [
        "Неочікувана помилка запиту"
      ],
      "Unexpected error": [
        "Неочікувана помилка"
      ],
      "An IBAN consists of capital letters and numbers only": [
        "IBAN повинен містити лише великі літери та цифри"
      ],
      "IBAN numbers have more that 4 digits": [
        "Номера IBAN зазвичай мають більше 4-ьох цифр"
      ],
      "IBAN numbers have less that 34 digits": [
        "Номера IBAN зазвичай мають менше 34-ьох цифр"
      ],
      "IBAN country code not found": [
        "Код країни IBAN не знайдено"
      ],
      "IBAN number is not valid, checksum is wrong": [
        "Номер IBAN не коректний, контрольна сума не сходиться"
      ],
      "Use letters, numbers or any of these characters: - . _ ~": [
        ""
      ],
      "Required": [
        "обовʼязково"
      ],
      "Not valid": [
        "недійсний"
      ],
      "Does not follow the pattern": [
        "не відповідає шаблону"
      ],
      "The request was invalid or the payto://-URI used unacceptable features.": [
        "Запит недійсний або payto://-URI використовує неприпустимі функції."
      ],
      "Not enough permission to complete the operation.": [
        "Недостатньо прав для виконання операції."
      ],
      "The bank administrator cannot be the transfer creditor.": [
        ""
      ],
      "The destination account \"%1$s\" was not found.": [
        "Обліковий запис призначення \"%1$s\" не знайдено."
      ],
      "The origin and the destination of the transfer can't be the same.": [
        "Джерело та місце призначення переказу не можуть бути однаковими."
      ],
      "Your balance is not sufficient for the operation.": [
        "Ваш баланс недостатній для виконання операції."
      ],
      "The origin account \"%1$s\" was not found.": [
        "Обліковий запис джерела \"%1$s\" не знайдено."
      ],
      "The attempt to create the transaction has failed. Please try again.": [
        ""
      ],
      "The wire transfer was successfully completed!": [
        "Банківський переказ завершено!"
      ],
      "Input wire transfer detail": [
        "Деталі банківського переказу"
      ],
      "Using a form": [
        "Використовуючи форму"
      ],
      "A special URI that specifies the amount to be transferred and the destination account.": [
        ""
      ],
      "QR code": [
        "Відправити код"
      ],
      "If your device has a camera, you can import a payto:// URI from a QR code.": [
        ""
      ],
      "Cashout": [
        "Виплати готівкою"
      ],
      "Recipient": [
        "Одержувач"
      ],
      "ID of the recipient's account": [
        "IBAN рахунку одержувача"
      ],
      "username": [
        "Імʼя користувача"
      ],
      "IBAN of the recipient's account": [
        "IBAN рахунку одержувача"
      ],
      "Transfer subject": [
        "Призначення переказу"
      ],
      "Subject": [
        "Призначення"
      ],
      "Some text to identify the transfer": [
        "текст для ідентифікації переказу"
      ],
      "Amount": [
        "Сума"
      ],
      "Amount to transfer": [
        "сума для переказу"
      ],
      "Payto URI:": [
        "payto URI:"
      ],
      "Uniform resource identifier of the target account": [
        "уніфікований ідентифікатор ресурсу цільового рахунку"
      ],
      "payto://x-taler-bank/[bank-host]/[receiver-account]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban-отримувача]?message=[призначення-платежу]&amount=[%1$s:X.Y]"
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban-отримувача]?message=[призначення-платежу]&amount=[%1$s:X.Y]"
      ],
      "The maximum amount for a wire transfer is %1$s": [
        ""
      ],
      "Cost": [
        ""
      ],
      "Cancel": [
        ""
      ],
      "Send": [
        ""
      ],
      "The target type is unknown, use \"%1$s\"": [
        ""
      ],
      "Only \"x-taler-bank\" target are supported": [
        "підтримуються лише цілі \"IBAN\""
      ],
      "Only this host is allowed. Use \"%1$s\"": [
        ""
      ],
      "Account name is missing": [
        "Оновлення облікового запису"
      ],
      "Only \"IBAN\" target are supported": [
        "підтримуються лише цілі \"IBAN\""
      ],
      "Missing \"amount\" parameter to specify the amount to be transferred": [
        "використовуйте параметр \"amount\", щоб вказати суму для переказу"
      ],
      "The \"amount\" parameter is not valid": [
        "сума недійсна"
      ],
      "\"message\" parameters to specify a reference text for the transfer are missing": [
        "використовуйте параметр \"message\", щоб вказати довідковий текст для переказу"
      ],
      "The only currency allowed is \"%1$s\"": [
        ""
      ],
      "You cannot transfer an amount of zero.": [
        "Ви не можете переказати суму, що дорівнює нулю."
      ],
      "The balance is not sufficient": [
        "На рахунку недостатньо коштів"
      ],
      "Please enter a longer subject": [
        "Призначення переказу"
      ],
      "Currently, the bank is not accepting new registrations!": [
        "Наразі банк не приймає нові реєстрації!"
      ],
      "The name is missing": [
        ""
      ],
      "Missing username": [
        "Відсутнє ім'я користувача"
      ],
      "Missing password": [
        "Відсутній пароль"
      ],
      "The password should be longer than 8 letters": [
        "має бути більшим за 0"
      ],
      "The passwords do not match": [
        "Паролі не збігаються"
      ],
      "Server replied with invalid phone or email.": [
        "Сервер відповів, що номер телефону або електронна пошта недійсні."
      ],
      "You are not authorised to create this account.": [
        "Недостатньо прав для створення цього облікового запису."
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "Реєстрація відключена, оскільки банк вичерпав бонусний кредит."
      ],
      "That username can't be used because is reserved.": [
        "Це ім'я користувача не можна використовувати, оскільки воно зарезервоване."
      ],
      "That username is already taken.": [
        "Це ім'я користувача вже зайняте."
      ],
      "That account ID is already taken.": [
        "Цей ідентифікатор облікового запису вже зайнятий."
      ],
      "No information for the selected authentication channel.": [
        "Немає інформації про обраний канал автентифікації."
      ],
      "Authentication channel is not supported.": [
        "Канал автентифікації не підтримується."
      ],
      "Only an administrator is allowed to set the debt limit.": [
        "Лише адміністратору дозволено встановлювати ліміт боргу."
      ],
      "Only the administrator can change the minimum cashout limit.": [
        ""
      ],
      "Only admin can create accounts with second factor authentication.": [
        "Лише адміністратор може створювати облікові записи з двофакторною автентифікацією."
      ],
      "The password is too short.": [
        "Паролі не збігаються"
      ],
      "The password is too long.": [
        "Паролі не збігаються"
      ],
      "Account registration": [
        "Реєстрація облікового запису"
      ],
      "Login username": [
        "Відсутнє ім'я користувача"
      ],
      "Password": [
        "Пароль"
      ],
      "Use a strong password: 8 characters minimum, don't use any public information related to you (names, birthday, phone number, etc...) and mix lowercase, uppercase, symbols and numbers": [
        ""
      ],
      "Repeat password": [
        "Повторіть пароль"
      ],
      "Full name": [
        ""
      ],
      "Register": [
        "Реєстрація"
      ],
      "Create a random temporary user": [
        "Створити випадкового тимчасового користувача"
      ],
      "Wrong credentials for \"%1$s\"": [
        "Неправильні облікові дані для \"%1$s\""
      ],
      "Account not found": [
        "Обліковий запис не знайдено"
      ],
      "Username": [
        "Імʼя користувача"
      ],
      "Username of the account": [
        "ім'я користувача облікового запису"
      ],
      "Password of the account": [
        "пароль облікового запису"
      ],
      "Check": [
        "Перевірити"
      ],
      "Log in": [
        "Увійти"
      ],
      "Transactions history": [
        ""
      ],
      "No transactions yet.": [
        "Транзакцій поки що немає."
      ],
      "You can make a transfer or a withdrawal to your wallet.": [
        ""
      ],
      "Date": [
        "Дата"
      ],
      "Counterpart": [
        "Контррахунок"
      ],
      "sent": [
        "відправлено"
      ],
      "received": [
        "отримано"
      ],
      "Invalid value": [
        "недійсне значення"
      ],
      "to": [
        "до"
      ],
      "from": [
        "від"
      ],
      "First page": [
        "Перша сторінка"
      ],
      "Next": [
        "Далі"
      ],
      "Wire transfer completed!": [
        "Банківський переказ завершено!"
      ],
      "The withdrawal has been aborted previously and can't be confirmed": [
        "Виведення коштів було скасовано раніше і не може бути підтверджено"
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.": [
        "Операцію зняття коштів не можна підтвердити, доки гаманець не прийме транзакцію."
      ],
      "The operation ID is invalid.": [
        "Ідентифікатор операції недійсний."
      ],
      "The operation was not found.": [
        "Операцію не знайдено."
      ],
      "The starting withdrawal amount and the confirmation amount differs.": [
        ""
      ],
      "The bank requires a bank account which has not been specified yet.": [
        ""
      ],
      "The reserve operation has been confirmed previously and can't be aborted": [
        "Операція резервування була підтверджена раніше і не може бути скасована"
      ],
      "Confirm the withdrawal operation": [
        "Підтвердити операцію зняття коштів"
      ],
      "Wire transfer details": [
        "Деталі банківського переказу"
      ],
      "Payment Service Provider's account": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "Payment Service Provider's account number": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "Payment Service Provider's name": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "Payment Service Provider's account bank hostname": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "Payment Service Provider's account id": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "Payment Service Provider's account address": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "No amount has yet been determined.": [
        ""
      ],
      "Transfer": [
        "Переказати"
      ],
      "Authentication required": [
        "Потрібна автентифікація"
      ],
      "This operation was created with another username": [
        "Ця операція була створена з іншим іменем користувача"
      ],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.": [
        "Не авторизовано для виконання операції, можливо, сесія закінчилася або пароль було змінено."
      ],
      "The operation was rejected due to insufficient funds.": [
        "Операцію було відхилено через недостатність коштів."
      ],
      "Withdrawal confirmed": [
        "Зняття коштів підтверджено"
      ],
      "The wire transfer to the Payment Service Provider has been initiated. You will shortly receive the requested amount in your Taler wallet.": [
        "Банківський переказ до оператора Taler було ініційовано. Незабаром ви отримаєте запитану суму у ваш гаманець Taler."
      ],
      "Do not show this again": [
        "Більше не показувати це"
      ],
      "Close": [
        "Закрити"
      ],
      "If you have a Taler wallet installed on this device": [
        "Якщо на цьому пристрої встановлено гаманець Taler"
      ],
      "Your wallet will display the details of the transaction including the fees (if applicable). If you do not yet have a wallet, please follow the instructions": [
        "Ви побачите деталі операції у вашомугаманці, включаючи комісії (якщо є). Якщо у вас його ще немає, ви можете встановити його, дотримуючись інструкцій у"
      ],
      "on this page": [
        "цій сторонці"
      ],
      "Withdraw": [
        "Зняття коштів"
      ],
      "In case you have a Taler wallet on another device": [
        "Або якщо у вас є гаманець на іншому пристрої"
      ],
      "Scan the QR below to start the withdrawal.": [
        "Скануйте QR-код нижче, щоб розпочати зняття коштів."
      ],
      "There is an operation already pending": [
        "Операція вже існує"
      ],
      "Complete the operation in": [
        "Завершіть або скасуйте операцію в"
      ],
      "this page": [
        "цій сторонці"
      ],
      "Invalid": [
        "недійсно"
      ],
      "Balance is not enough": [
        "недостатній баланс"
      ],
      "The server replied with an invalid taler://withdraw URI": [
        "Сервер відповів недійсним URI для зняття коштів"
      ],
      "Withdraw URI: %1$s": [
        "URI для зняття коштів: %1$s"
      ],
      "The operation was rejected due to insufficient funds": [
        "Операцію було відхилено через брак коштів"
      ],
      "Current balance is %1$s": [
        ""
      ],
      "You can withdraw up to %1$s": [
        ""
      ],
      "Continue": [
        "Продовжити"
      ],
      "Use your Taler wallet": [
        "Підготуйте свій гаманець"
      ],
      "After using your wallet you will need to authorize or cancel the operation on this site.": [
        "Після використання вашого гаманця Вам потрібно буде підтвердити або скасувати операцію на цьому сайті."
      ],
      "You need a Taler wallet": [
        "Вам потрібен гаманець GNU Taler"
      ],
      "If you don't have one yet you can follow the instruction in": [
        "Якщо у вас його ще немає, ви можете дотримуватися інструкцій у"
      ],
      "Send money": [
        "Надіслати гроші"
      ],
      "to a Taler wallet": [
        "до гаманця %1$s"
      ],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "Зніміть цифрові гроші у Ваш мобільний гаманець або розширення браузера"
      ],
      "to another bank account": [
        "на інший банківський рахунок"
      ],
      "Make a wire transfer to an account with known bank account number.": [
        "Здійсніть банківський переказ на рахунок із відомим номером банківського рахунку."
      ],
      "This is a demo": [
        "Це демонстраційний банк"
      ],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s .": [
        "Ця частина демонстрації показує, як працював би банк, що безпосередньо підтримує Taler. Окрім використання вашого власного банківського рахунку, ви також можете переглянути історію транзакцій деяких %1$s."
      ],
      "Here you will be able to see how a bank that supports Taler directly would work.": [
        "Ця частина демонстрації показує, як працював би банк, що безпосередньо підтримує Taler."
      ],
      "Pending account delete operation": [
        "Очікування операції видалення облікового запису"
      ],
      "Pending account update operation": [
        "Очікування операції оновлення облікового запису"
      ],
      "Pending password update operation": [
        "Очікування операції оновлення пароля"
      ],
      "Pending transaction operation": [
        "Очікування операції транзакції"
      ],
      "Pending withdrawal operation": [
        "Очікування операції зняття коштів"
      ],
      "Pending cashout operation": [
        "Очікування операції зняття готівки"
      ],
      "You can complete or cancel the operation in": [
        "Ви можете завершити або скасувати операцію в"
      ],
      "Internal error, please report.": [
        "Внутрішня помилка, будь ласка, повідомте про це."
      ],
      "Preferences": [
        "Налаштування"
      ],
      "Welcome": [
        "Вітаємо"
      ],
      "Welcome, %1$s": [
        "Вітаємо, %1$s"
      ],
      "History of public accounts": [
        "Історія публічних рахунків"
      ],
      "Scan the QR code below to start the withdrawal.": [
        "Скануйте QR-код нижче, щоб розпочати зняття коштів."
      ],
      "Operation aborted": [
        "Операцію скасовано"
      ],
      "The wire transfer to the Payment Service Provider's account was aborted from somewhere else, your balance was not affected.": [
        "Банківський переказ на рахунок оператора Taler Exchange було скасовано, ваш баланс не постраждав."
      ],
      "Go to your wallet now": [
        "Перейти до гаманця зараз"
      ],
      "The operation is marked as selected, but a process during the withdrawal failed": [
        "Операція позначена як 'вибрана', але деякий крок у процесі зняття коштів не вдалося виконати"
      ],
      "The account was selected, but no withdrawal reserve ID was found.": [
        "Обліковий запис вибрано, але ідентифікацію зняття коштів не знайдено."
      ],
      "There is a withdrawal reserve ID but no account has been selected or the selected account is invalid.": [
        "Є ідентифікація зняття коштів, але обліковий запис не вибрано або вибраний обліковий запис недійсний."
      ],
      "A withdrawal reserve ID was not found and the no account has been selected.": [
        "Ідентифікатор зняття коштів не знайдено, обліковий запис не вибрано або вибраний обліковий запис недійсний."
      ],
      "Operation not found": [
        "Операцію не знайдено"
      ],
      "This process is not known to the server. The process ID is incorrect or the server has deleted the process information before it arrived here.": [
        "Ця операція невідома серверу. Ідентифікатор операції неправильний або сервер видалив інформацію про операцію до її завершення."
      ],
      "Continue to dashboard": [
        "Перейти до панелі керування"
      ],
      "Confirmation codes are numerical, possibly beginning with 'T-.'": [
        ""
      ],
      "No cashout was found. The cashout process has probably already been aborted.": [
        "Зняття готівки не знайдено. Це також може означати, що його вже скасовано."
      ],
      "Challenge not found.": [
        "Виклик не знайдено."
      ],
      "This user is not authorized to complete this challenge.": [
        "Цей користувач не має права виконати цей виклик."
      ],
      "Too many attempts, try another code.": [
        "Забагато спроб, спробуйте інший код."
      ],
      "The confirmation code is wrong, try again.": [
        "Код підтвердження неправильний, спробуйте ще раз."
      ],
      "The operation expired.": [
        "Термін дії операції закінчився."
      ],
      "The operation failed.": [
        "Операція не вдалася."
      ],
      "The operation needs another confirmation to complete.": [
        "Для завершення операції потрібне ще одне підтвердження."
      ],
      "Confirm the operation": [
        "Підтвердити операцію"
      ],
      "This operation is protected with second factor authentication. In order to complete it we need to verify your identity using the authentication channel you provided.": [
        ""
      ],
      "Enter the confirmation code": [
        "Введіть код підтвердження"
      ],
      "You should have received a code on your mobile phone.": [
        ""
      ],
      "You should have received a code in your email.": [
        ""
      ],
      "The confirmation code starts with \"%1$s\" followed by numbers.": [
        "Код підтвердження починається з \"%1$s\", за яким йдуть цифри."
      ],
      "Confirm": [
        "Підтвердити"
      ],
      "Removing account": [
        "На рахунок"
      ],
      "Updating account values": [
        "Оновлення значень облікових записів"
      ],
      "Updating password": [
        "Оновити пароль"
      ],
      "Making a wire transfer": [
        "Здійснити банківський переказ"
      ],
      "Confirming withdrawal": [
        "Підтвердити операцію зняття коштів"
      ],
      "Making a cashout": [
        ""
      ],
      "Operation:": [
        "Операція:"
      ],
      "Type": [
        ""
      ],
      "Updating account settings": [
        "Очікування операції видалення облікового запису"
      ],
      "Account": [
        "Рахунки"
      ],
      "To account": [
        "На рахунок"
      ],
      "Cashout account": [
        "Відсутній рахунок для зняття готівки"
      ],
      "Email": [
        "Email"
      ],
      "Phone": [
        "Телефон"
      ],
      "Debit threshold": [
        "Дебетовано"
      ],
      "Is this account public?": [
        "Цей обліковий запис є публічним?"
      ],
      "Enable": [
        ""
      ],
      "Disable": [
        ""
      ],
      "Name": [
        "Назва"
      ],
      "Authentication channel": [
        "Потрібна автентифікація"
      ],
      "Remove": [
        "видалити"
      ],
      "New password": [
        "Новий пароль"
      ],
      "Challenge details": [
        "Деталі підтвердження"
      ],
      "Sent at": [
        "Надіслано о"
      ],
      "To phone": [
        "На телефон"
      ],
      "To email": [
        "На email"
      ],
      "Send again": [
        "Відправити знову"
      ],
      "Withdraw reserve ID": [
        "Зняття коштів"
      ],
      "Cashout is disabled": [
        "Зняття готівки створено"
      ],
      "Cashout should be enabled in the configuration, the conversion rate should be initialized with fee(s), rates and a rounding mode.": [
        ""
      ],
      "Make a wire transfer": [
        "Здійснити банківський переказ"
      ],
      "The Withdrawal URI is not valid": [
        "URI для зняття коштів недійсний"
      ],
      "Cashout should be enable by configuration and the conversion rate should be initialized with fee, ratio and rounding mode.": [
        ""
      ],
      "Latest cashouts": [
        "Останні зняття готівки"
      ],
      "Created": [
        "Створено"
      ],
      "Total debit": [
        "Загальний дебет"
      ],
      "Total credit": [
        "Загальний кредит"
      ],
      "Select a section": [
        "Оберіть розділ"
      ],
      "Details": [
        "Деталі"
      ],
      "Delete": [
        "Видалити"
      ],
      "Credentials": [
        "Облікові дані"
      ],
      "Cashouts": [
        "Зняття готівки"
      ],
      "Conversion": [
        "Обмінний курс"
      ],
      "Unable to create a cashout": [
        "Не вдалося створити зняття готівки"
      ],
      "The bank configuration does not support cashout operations.": [
        "Конфігурація банку не підтримує операції зі зняття готівки."
      ],
      "Amount needs to be higher": [
        "повинна бути вищою через комісії"
      ],
      "It is not possible to cashout less than %1$s": [
        ""
      ],
      "Your account can't cashout less than %1$s": [
        ""
      ],
      "The total transfer to the destination will be zero": [
        "загальна сума переказу на місці призначення буде нульовою"
      ],
      "Cashout created": [
        "Зняття готівки створено"
      ],
      "Duplicated request detected, check if the operation succeeded or try again.": [
        "Виявлено повторний запит, перевірте, чи була операція успішною, або спробуйте ще раз."
      ],
      "The conversion rate was applied incorrectly": [
        "Курс обміну було застосовано неправильно"
      ],
      "The account does not have sufficient funds": [
        "На рахунку недостатньо коштів"
      ],
      "Missing cashout URI in the profile": [
        "Відсутній URI зняття готівки в профілі"
      ],
      "The amount is below the minimum amount permitted.": [
        ""
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.": [
        "Не вдалося надіслати повідомлення з підтвердженням, спробуйте пізніше або зверніться до адміністратора."
      ],
      "The server doesn't support the current TAN channel.": [
        "Цей сервер не підтримує двофакторну автентифікацію."
      ],
      "Conversion rate": [
        "Обмінний курс"
      ],
      "Balance": [
        "Баланс"
      ],
      "Fee": [
        "Комісія"
      ],
      "Legal name": [
        ""
      ],
      "If this name doesn't match the account holder's name, your transaction may fail.": [
        ""
      ],
      "No cashout account": [
        "Відсутній рахунок для зняття готівки"
      ],
      "Before being able to cashout to a bank account, you need to complete your profile": [
        "Перш ніж здійснити зняття готівки, вам потрібно заповнити свій профіль"
      ],
      "Currency": [
        ""
      ],
      "Send %1$s": [
        ""
      ],
      "Receive %1$s": [
        "Вітаємо, %1$s"
      ],
      "Total cost": [
        "Загальна вартість"
      ],
      "Balance left": [
        "Залишок балансу"
      ],
      "Before fee": [
        "Комісія до"
      ],
      "Total cashout transfer": [
        "Загальна сума зняття готівки"
      ],
      "Cashout for account %1$s": [
        "Зняття готівки для облікового запису %1$s"
      ],
      "Doesn't have the pattern of an email": [
        "він не відповідає шаблону електронної пошти"
      ],
      "Should start with +": [
        "повинен починатися з +"
      ],
      "A phone number consists of numbers only": [
        "номер телефону повинен містити лише цифри"
      ],
      "Account ID for authentication": [
        "Двофакторна автентифікація"
      ],
      "Name of the account holder": [
        "ім'я користувача облікового запису"
      ],
      "Internal account": [
        "на інший банківський рахунок"
      ],
      "If this field is empty, a random account ID will be assigned": [
        "якщо порожньо, буде призначено випадковий номер рахунку"
      ],
      "You can copy and share this IBAN number in order to receive wire transfers to your bank account": [
        ""
      ],
      "To be used when second factor authentication is enabled": [
        "Увімкнути двофакторну автентифікацію"
      ],
      "External account number where the money is going to be sent when doing cashouts": [
        "номер рахунку, на який будуть відправлені гроші при знятті готівки"
      ],
      "Max debt": [
        "Максимальний борг"
      ],
      "How much the balance can go below zero.": [
        ""
      ],
      "Minimum cashout": [
        "виплати готівкою"
      ],
      "Custom minimum cashout amount for this account.": [
        ""
      ],
      "Public accounts have their balance publicly accessible": [
        "публічні рахунки мають публічно доступний баланс"
      ],
      "Does this account belong to a Payment Service Provider?": [
        "Цей обліковий запис є публічним?"
      ],
      "Account updated": [
        "Рахунок оновлено"
      ],
      "The rights to change the account are not sufficient": [
        "Недостатньо прав для зміни облікового запису"
      ],
      "The username was not found": [
        "Ім'я користувача не знайдено"
      ],
      "You can't change the legal name, please contact the your account administrator.": [
        "Ви не можете змінити юридичне ім'я, будь ласка, зверніться до адміністратора вашого облікового запису."
      ],
      "You can't change the debt limit, please contact the your account administrator.": [
        "Ви не можете змінити ліміт боргу, будь ласка, зверніться до адміністратора вашого облікового запису."
      ],
      "You can't change the cashout address, please contact the your account administrator.": [
        "Ви не можете змінити адресу зняття готівки, будь ласка, зверніться до адміністратора вашого облікового запису."
      ],
      "Account \"%1$s\"": [
        "Рахунок \"%1$s\""
      ],
      "Removed": [
        "видалити"
      ],
      "This account can't be used.": [
        "Цей крок не можна скасувати."
      ],
      "Change details": [
        "Зміна реквізитів"
      ],
      "Update": [
        "Оновити"
      ],
      "Merchant integration": [
        "Реєстрація облікового запису"
      ],
      "Use this information to link your Taler Merchant Backoffice account with the current bank account. You can start by copying the values, then go to your merchant backoffice service provider, login into your account and look for the \"import\" button in the \"bank account\" section.": [
        ""
      ],
      "Account type": [
        "Видалення облікового запису"
      ],
      "Method to use for wire transfer.": [
        "Здійснити банківський переказ"
      ],
      "IBAN": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Account name": [
        "Оновлення облікового запису"
      ],
      "Bank host where the service is located.": [
        ""
      ],
      "Bank account identifier for wire transfers.": [
        "ідентифікація облікового запису для банківського переказу"
      ],
      "Address": [
        ""
      ],
      "Owner's name": [
        "Імʼя користувача"
      ],
      "Legal name of the person holding the account.": [
        "ім'я особи, якій належить обліковий запис"
      ],
      "Account info URL": [
        "Обліковий запис не знайдено"
      ],
      "From where the merchant can download information about incoming wire transfers to this account.": [
        ""
      ],
      "Copy": [
        ""
      ],
      "Repeated password doesn't match": [
        "пароль не співпадає"
      ],
      "Password changed": [
        "Пароль змінено"
      ],
      "Not authorized to change the password, maybe the session is invalid.": [
        "Немає прав для зміни пароля, можливо, сеанс недійсний."
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.": [
        "Вам потрібно надати старий пароль. Якщо у вас його немає, зверніться до адміністратора вашого облікового запису."
      ],
      "Your current password doesn't match, can't change to a new password.": [
        "Ваш поточний пароль не збігається, не вдалося змінити на новий пароль."
      ],
      "Update password": [
        "Оновити пароль"
      ],
      "Current password": [
        "Поточний пароль"
      ],
      "Your current password, for security": [
        "ваш поточний пароль, для безпеки"
      ],
      "Type it again": [
        "Введіть його ще раз"
      ],
      "Repeat the same password": [
        "повторіть той самий пароль"
      ],
      "Change": [
        "Змінити"
      ],
      "Accounts": [
        "Рахунки"
      ],
      "Create account": [
        "Створити обліковий запис"
      ],
      "Actions": [
        "Дії"
      ],
      "Unknown": [
        "невідомо"
      ],
      "Change password": [
        "змінити пароль"
      ],
      "Querying for the current stats failed": [
        ""
      ],
      "The request parameters are wrong": [
        ""
      ],
      "The user is unauthorized": [
        "Ім'я користувача не знайдено"
      ],
      "Querying for the previous stats failed": [
        ""
      ],
      "Transaction volume report": [
        ""
      ],
      "Last hour": [
        "Остання година"
      ],
      "Previous day": [
        ""
      ],
      "Last month": [
        "Останній місяць"
      ],
      "Last year": [
        "Останній рік"
      ],
      "Last Year": [
        "Попередній рік"
      ],
      "Trading volume from %1$s to %2$s": [
        "Обсяг торгів на %1$s порівняно з %2$s"
      ],
      "Cashin": [
        "Поповнення готівкою"
      ],
      "Transferred from an external account to an account in this bank.": [
        ""
      ],
      "Transferred from an account in this bank to an external account.": [
        "Здійсніть банківський переказ на рахунок із відомим номером банківського рахунку."
      ],
      "Payin": [
        "Внесення коштів"
      ],
      "Transferred from an account to a Taler exchange.": [
        ""
      ],
      "Payout": [
        "Виплата"
      ],
      "Transferred from a Taler exchange to another account.": [
        "Обліковий запис оператора обмінного пункту Taler"
      ],
      "Download stats as CSV": [
        "завантажити статистику у форматі CSV"
      ],
      "previous": [
        ""
      ],
      "Decreased by": [
        "Зменшилось на"
      ],
      "Increased by": [
        "Збільшилось на"
      ],
      "Account created with password \"%1$s\".": [
        ""
      ],
      "Server replied that phone or email is invalid": [
        "Сервер відповів, що номер телефону або електронна пошта недійсні"
      ],
      "The rights to perform the operation are not sufficient": [
        "Недостатньо прав для виконання операції"
      ],
      "Account username is already taken": [
        "Ім'я користувача облікового запису вже зайнято"
      ],
      "Account id is already taken": [
        "Ідентифікатор облікового запису вже зайнятий"
      ],
      "Bank ran out of bonus credit.": [
        "У банку закінчився бонусний кредит."
      ],
      "Account username can't be used because is reserved": [
        "Ім'я користувача облікового запису не можна використовувати, оскільки воно зарезервоване"
      ],
      "Can't create accounts": [
        "Не вдається створити рахунки"
      ],
      "Only system admin can create accounts.": [
        "Лише системний адміністратор може створювати рахунки."
      ],
      "New bank account": [
        "Новий бізнес рахунок"
      ],
      "Create": [
        "Створити"
      ],
      "Download bank stats": [
        "Завантажити статистику банку"
      ],
      "Include hour metric": [
        "Включити часову метрику"
      ],
      "Include day metric": [
        "Включити добову метрику"
      ],
      "Include month metric": [
        "Включити місячну метрику"
      ],
      "Include year metric": [
        "Включити річну метрику"
      ],
      "Include table header": [
        "Включити заголовок таблиці"
      ],
      "Add previous metric for compare": [
        "Додати попередню метрику для порівняння"
      ],
      "Fail on first error": [
        "Збій на першій помилці"
      ],
      "Download": [
        "Завантажити"
      ],
      "downloading... %1$s": [
        "завантаження...%1$s"
      ],
      "Download completed": [
        "Завантаження завершено"
      ],
      "Click here to save the file in your computer.": [
        "натисніть тут, щоб зберегти файл на вашому комп'ютері"
      ],
      "Can't delete the account": [
        "Не вдається видалити обліковий запис"
      ],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.": [
        "Обліковий запис не можна видалити, поки на ньому є баланс. Спочатку переконайтеся, що власник зробив повне зняття коштів."
      ],
      "Account removed": [
        "Обліковий запис видалено"
      ],
      "No enough permission to delete the account.": [
        "Недостатньо прав для видалення облікового запису."
      ],
      "The username was not found.": [
        "Ім'я користувача не знайдено."
      ],
      "Can't delete a reserved username.": [
        "Не можна видалити зарезервоване ім'я користувача."
      ],
      "Can't delete an account with balance different than zero.": [
        "Не можна видалити обліковий запис з балансом, відмінним від нуля."
      ],
      "Name doesn't match": [
        "ім'я не збігається"
      ],
      "You are going to remove the account": [
        "Ви збираєтеся видалити обліковий запис"
      ],
      "This step can't be undone.": [
        "Цей крок не можна скасувати."
      ],
      "Deleting account \"%1$s\"": [
        "Видалення рахунку \"%1$s\""
      ],
      "Verification": [
        "Підтвердження"
      ],
      "Enter the account name that is going to be deleted": [
        "введіть ім'я рахунку, який буде видалено"
      ],
      "loading...": [
        "завантаження...%1$s"
      ],
      "only admin can setup conversion": [
        "Лише системний адміністратор може створювати рахунки."
      ],
      "Wrong credentials": [
        "Неправильні облікові дані для \"%1$s\""
      ],
      "Conversion is disabled": [
        "Обмінний курс"
      ],
      "Config cashout": [
        "Виплати готівкою"
      ],
      "Config cashin": [
        "Поповнення готівкою"
      ],
      "Cashin ratio": [
        "Поповнення готівкою"
      ],
      "Cashout ratio": [
        "Зняття готівки створено"
      ],
      "Bad ratios": [
        ""
      ],
      "One of the ratios should be higher or equal than 1 an the other should be lower or equal than 1.": [
        ""
      ],
      "Initial amount": [
        "Максимальна сума зняття"
      ],
      "Use it to test how the conversion will affect the amount.": [
        ""
      ],
      "Sending to this bank": [
        ""
      ],
      "Converted": [
        "Обмінний курс"
      ],
      "Cashin after fee": [
        ""
      ],
      "Sending from this bank": [
        ""
      ],
      "Cashout after fee": [
        "Зняття готівки створено"
      ],
      "Bad configuration": [
        ""
      ],
      "This configuration allows users to cash out more of what has been cashed in.": [
        ""
      ],
      "Rnvalid": [
        "недійсно"
      ],
      "Minimum amount": [
        ""
      ],
      "Only cashout operation above this threshold will be allowed": [
        ""
      ],
      "Ratio": [
        ""
      ],
      "Conversion ratio between currencies": [
        "Обмінний курс"
      ],
      "Example conversion": [
        ""
      ],
      "1 %1$s will be converted into %2$s %3$s": [
        ""
      ],
      "Rounding value": [
        ""
      ],
      "Smallest difference between two amounts after the ratio is applied.": [
        ""
      ],
      "Rounding mode": [
        ""
      ],
      "Zero": [
        ""
      ],
      "Amount will be round below to the largest possible value smaller than the input.": [
        ""
      ],
      "Up": [
        ""
      ],
      "Amount will be round up to the smallest possible value larger than the input.": [
        ""
      ],
      "Nearest": [
        ""
      ],
      "Amount will be round to the closest possible value.": [
        ""
      ],
      "Examples": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.1": [
        ""
      ],
      "Given the rounding value of 0.1 the possible values closest to 1.24 are: 1.1, 1.2, 1.3, 1.4.": [
        ""
      ],
      "With the \"zero\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.1": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.3": [
        ""
      ],
      "Given the rounding value of 0.3 the possible values closest to 1.24 are: 0.9, 1.2, 1.5, 1.8.": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.5": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.3": [
        ""
      ],
      "Amount to be deducted before amount is credited.": [
        ""
      ],
      "Cashout id should be a number": [
        "ідентифікатор зняття готівки повинен бути числом"
      ],
      "This cashout not found. Maybe already aborted.": [
        "Це зняття готівки не знайдено. Можливо, його вже скасовано."
      ],
      "Cashout detail": [
        "Деталі зняття готівки"
      ],
      "Debited": [
        "Дебетовано"
      ],
      "Credited": [
        "Кредитовано"
      ],
      "Welcome to %1$s!": [
        "Ласкаво просимо до %1$s!"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=3; plural=n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2;",
  "lang": "uk",
  "completeness": 78
};

strings['ru'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=3; plural=n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2;",
        "lang": "ru"
      },
      "Show withdrawal confirmation": [
        "Показать подтверждение вывода средств"
      ],
      "Withdraw without setting amount": [
        ""
      ],
      "Show copy account letter": [
        ""
      ],
      "Show demo description": [
        "Показать описание демо"
      ],
      "Show install wallet first": [
        "Сначала показать как установить кошелёк"
      ],
      "Remove password length validation on registration": [
        ""
      ],
      "Show debug info": [
        "Показать информацию для отладки"
      ],
      "Operation failed, please report": [
        "Не удалось выполнить операцию, сообщите об этом"
      ],
      "Request timeout": [
        "Тайм-аут запроса"
      ],
      "Request throttled": [
        "Запрос замедлен"
      ],
      "Malformed response": [
        "Неправильный ответ"
      ],
      "Network error": [
        "Ошибка сети"
      ],
      "Unexpected request error": [
        "Неожиданная ошибка запроса"
      ],
      "Unexpected error": [
        "Непредвиденная ошибка"
      ],
      "An IBAN consists of capital letters and numbers only": [
        "IBAN должен состоять только из прописных букв и цифр"
      ],
      "IBAN numbers have more that 4 digits": [
        "Номера IBAN обычно содержат более 4 цифр"
      ],
      "IBAN numbers have less that 34 digits": [
        "Номера IBAN обычно содержат менее 34 цифр"
      ],
      "IBAN country code not found": [
        "Код страны IBAN не найден"
      ],
      "IBAN number is not valid, checksum is wrong": [
        "Номер IBAN недействителен, контрольная сумма неверна"
      ],
      "Use letters, numbers or any of these characters: - . _ ~": [
        ""
      ],
      "Required": [
        "обязательно"
      ],
      "Not valid": [
        "недопустимый"
      ],
      "Does not follow the pattern": [
        "не следует шаблону"
      ],
      "The request was invalid or the payto://-URI used unacceptable features.": [
        "Запрос был неверным или payto://-URI использовал недопустимую функциональность."
      ],
      "Not enough permission to complete the operation.": [
        "Не хватает разрешения для завершения операции."
      ],
      "The bank administrator cannot be the transfer creditor.": [
        ""
      ],
      "The destination account \"%1$s\" was not found.": [
        "Целевой счет \"%1$s\" не найден."
      ],
      "The origin and the destination of the transfer can't be the same.": [
        "Пункт отправления и пункт назначения перевода не могут совпадать."
      ],
      "Your balance is not sufficient for the operation.": [
        "Вашего баланса недостаточно для проведения операции."
      ],
      "The origin account \"%1$s\" was not found.": [
        "Исходный аккаунт \"%1$s\" не найден."
      ],
      "The attempt to create the transaction has failed. Please try again.": [
        ""
      ],
      "The wire transfer was successfully completed!": [
        "Отправка перевода завершена!"
      ],
      "Input wire transfer detail": [
        "Детали банковского перевода"
      ],
      "Using a form": [
        "Используя форму"
      ],
      "A special URI that specifies the amount to be transferred and the destination account.": [
        ""
      ],
      "QR code": [
        "Отправить код"
      ],
      "If your device has a camera, you can import a payto:// URI from a QR code.": [
        ""
      ],
      "Cashout": [
        "Выплата"
      ],
      "Recipient": [
        "Получатель"
      ],
      "ID of the recipient's account": [
        "IBAN счета получателя"
      ],
      "username": [
        "Имя пользователя"
      ],
      "IBAN of the recipient's account": [
        "IBAN счета получателя"
      ],
      "Transfer subject": [
        "Причина перевода"
      ],
      "Subject": [
        "Причина"
      ],
      "Some text to identify the transfer": [
        "какой-то текст для идентификации перевода"
      ],
      "Amount": [
        "Сумма"
      ],
      "Amount to transfer": [
        "сумма для перевода"
      ],
      "Payto URI:": [
        "payto URI:"
      ],
      "Uniform resource identifier of the target account": [
        "унифицированный идентификатор ресурса целевой учетной записи"
      ],
      "payto://x-taler-bank/[bank-host]/[receiver-account]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban_получателя]?message=[причина_платежа]&amount=[%1$s:X.Y]"
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban_получателя]?message=[причина_платежа]&amount=[%1$s:X.Y]"
      ],
      "The maximum amount for a wire transfer is %1$s": [
        ""
      ],
      "Cost": [
        ""
      ],
      "Cancel": [
        "Отмена"
      ],
      "Send": [
        "Отправить"
      ],
      "The target type is unknown, use \"%1$s\"": [
        ""
      ],
      "Only \"x-taler-bank\" target are supported": [
        "поддерживаются только \"IBAN\""
      ],
      "Only this host is allowed. Use \"%1$s\"": [
        ""
      ],
      "Account name is missing": [
        "Обновление счёта"
      ],
      "Only \"IBAN\" target are supported": [
        "поддерживаются только \"IBAN\""
      ],
      "Missing \"amount\" parameter to specify the amount to be transferred": [
        "Используйте параметр \"Сумма\" для указания суммы перевода"
      ],
      "The \"amount\" parameter is not valid": [
        "сумма не является действительной"
      ],
      "\"message\" parameters to specify a reference text for the transfer are missing": [
        "используйте параметр \"message\" для текста причины перевода"
      ],
      "The only currency allowed is \"%1$s\"": [
        ""
      ],
      "You cannot transfer an amount of zero.": [
        ""
      ],
      "The balance is not sufficient": [
        "На счете недостаточно средств"
      ],
      "Please enter a longer subject": [
        "Причина перевода"
      ],
      "Currently, the bank is not accepting new registrations!": [
        "В настоящее время банк не принимает новые регистрации!"
      ],
      "The name is missing": [
        ""
      ],
      "Missing username": [
        "Отсутствует имя пользователя"
      ],
      "Missing password": [
        "Отсутствует пароль"
      ],
      "The password should be longer than 8 letters": [
        "должно быть больше 0"
      ],
      "The passwords do not match": [
        "Пароли не совпадают"
      ],
      "Server replied with invalid phone or email.": [
        "Сервер ответил что телефон или электронной почта недействительны."
      ],
      "You are not authorised to create this account.": [
        "Недостаточно разрешений для создания этого счёта."
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "Регистрация отключена, так как в банке закончился бонусный кредит."
      ],
      "That username can't be used because is reserved.": [
        "Это имя пользователя не может быть использовано, так как оно зарезервировано."
      ],
      "That username is already taken.": [
        "Это имя пользователя уже используется."
      ],
      "That account ID is already taken.": [
        "Этот идентификатор счёта уже занят."
      ],
      "No information for the selected authentication channel.": [
        "Нет информации о выбранном канале аутентификации."
      ],
      "Authentication channel is not supported.": [
        "Канал аутентификации не поддерживается."
      ],
      "Only an administrator is allowed to set the debt limit.": [
        "Только администратор может установить лимит задолженности."
      ],
      "Only the administrator can change the minimum cashout limit.": [
        ""
      ],
      "Only admin can create accounts with second factor authentication.": [
        "Только администратор может создавать учетные записи со второй аутентификацией."
      ],
      "The password is too short.": [
        ""
      ],
      "The password is too long.": [
        "Пароль слишком длинный."
      ],
      "Account registration": [
        "Регистрация счёта"
      ],
      "Login username": [
        "Отсутствует имя пользователя"
      ],
      "Password": [
        "Пароль"
      ],
      "Use a strong password: 8 characters minimum, don't use any public information related to you (names, birthday, phone number, etc...) and mix lowercase, uppercase, symbols and numbers": [
        ""
      ],
      "Repeat password": [
        "Повторите Пароль"
      ],
      "Full name": [
        ""
      ],
      "Register": [
        "Регистрация"
      ],
      "Create a random temporary user": [
        "Создать случайного временного пользователя"
      ],
      "Wrong credentials for \"%1$s\"": [
        "Неверные учетные данные для «%1$s» ‎"
      ],
      "Account not found": [
        "Учётная запись не найдена"
      ],
      "Username": [
        "Имя пользователя"
      ],
      "Username of the account": [
        "имя пользователя счёта"
      ],
      "Password of the account": [
        "пароль от счёта"
      ],
      "Check": [
        "Проверить"
      ],
      "Log in": [
        "Войти"
      ],
      "Transactions history": [
        ""
      ],
      "No transactions yet.": [
        "Транзакций пока нет."
      ],
      "You can make a transfer or a withdrawal to your wallet.": [
        ""
      ],
      "Date": [
        "Дата"
      ],
      "Counterpart": [
        "Контрасчет"
      ],
      "sent": [
        "отправлено"
      ],
      "received": [
        "получено"
      ],
      "Invalid value": [
        "Недопустимое значение"
      ],
      "to": [
        "к"
      ],
      "from": [
        "от"
      ],
      "First page": [
        "Первая страница"
      ],
      "Next": [
        "Далее"
      ],
      "Wire transfer completed!": [
        "Отправка перевода завершена!"
      ],
      "The withdrawal has been aborted previously and can't be confirmed": [
        "Вывод средств был прерван ранее и не может быть подтвержден"
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.": [
        "Операция по выводу средств не может быть подтверждена до того как кошёлек примет транзакцию."
      ],
      "The operation ID is invalid.": [
        "Идентификатор операции недействителен."
      ],
      "The operation was not found.": [
        "Операция не найдена."
      ],
      "The starting withdrawal amount and the confirmation amount differs.": [
        ""
      ],
      "The bank requires a bank account which has not been specified yet.": [
        ""
      ],
      "The reserve operation has been confirmed previously and can't be aborted": [
        "Резервная операция была подтверждена ранее и не может быть прервана"
      ],
      "Confirm the withdrawal operation": [
        "Подтвердите операцию вывода"
      ],
      "Wire transfer details": [
        "Детали банковского перевода"
      ],
      "Payment Service Provider's account": [
        "Счет оператора Обменника Taler"
      ],
      "Payment Service Provider's account number": [
        "Счет оператора Обменника Taler"
      ],
      "Payment Service Provider's name": [
        "Счет оператора Обменника Taler"
      ],
      "Payment Service Provider's account bank hostname": [
        "Счет оператора Обменника Taler"
      ],
      "Payment Service Provider's account id": [
        "Счет оператора Обменника Taler"
      ],
      "Payment Service Provider's account address": [
        "Счет оператора Обменника Taler"
      ],
      "No amount has yet been determined.": [
        ""
      ],
      "Transfer": [
        "Перевести"
      ],
      "Authentication required": [
        "Требуется аутентификация"
      ],
      "This operation was created with another username": [
        "Эта операция была создана с другим именем пользователя"
      ],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.": [
        "Неавторизированное выполнение операции, возможно истек сеанс или изменён пароль."
      ],
      "The operation was rejected due to insufficient funds.": [
        "Операция отклонена из-за нехватки средств."
      ],
      "Withdrawal confirmed": [
        "Вывод подтверждён"
      ],
      "The wire transfer to the Payment Service Provider has been initiated. You will shortly receive the requested amount in your Taler wallet.": [
        "Инициирован банковский перевод оператору Taler. Вскоре вы получите запрошенную сумму на свой кошелёк Taler."
      ],
      "Do not show this again": [
        "Не показывать снова"
      ],
      "Close": [
        "Закрыть"
      ],
      "If you have a Taler wallet installed on this device": [
        "Если в этом устройстве установлен кошелёк Taler"
      ],
      "Your wallet will display the details of the transaction including the fees (if applicable). If you do not yet have a wallet, please follow the instructions": [
        "Вы увидите подробности операции в своем кошельке, включая комиссию (если применимо). Если у вас его еще нет, вы можете установить его следуя инструкциям на"
      ],
      "on this page": [
        "этой странице"
      ],
      "Withdraw": [
        "Снять средства"
      ],
      "In case you have a Taler wallet on another device": [
        "Или если у вас есть кошелёк в другом устройстве"
      ],
      "Scan the QR below to start the withdrawal.": [
        "Отсканируйте QR-код ниже чтобы начать вывод средств."
      ],
      "There is an operation already pending": [
        "Операция уже идет"
      ],
      "Complete the operation in": [
        "Завершите или отмените операцию в"
      ],
      "this page": [
        "этой странице"
      ],
      "Invalid": [
        "недействительно"
      ],
      "Balance is not enough": [
        "Недостаточно средств на балансе"
      ],
      "The server replied with an invalid taler://withdraw URI": [
        "Сервер ответил с недопустимым URI вывода"
      ],
      "Withdraw URI: %1$s": [
        "URI вывода: %1$s"
      ],
      "The operation was rejected due to insufficient funds": [
        "Операция отклонена из-за нехватки средств."
      ],
      "Current balance is %1$s": [
        ""
      ],
      "You can withdraw up to %1$s": [
        ""
      ],
      "Continue": [
        "Продолжить"
      ],
      "Use your Taler wallet": [
        "Подготовьте свой кошелёк"
      ],
      "After using your wallet you will need to authorize or cancel the operation on this site.": [
        "После использования кошелька вам нужно будет подтвердить или отменить операцию на этом сайте."
      ],
      "You need a Taler wallet": [
        "Вам нужен кошелёк Taler"
      ],
      "If you don't have one yet you can follow the instruction in": [
        "Если у вас его еще нет, вы можете следовать инструкциям на"
      ],
      "Send money": [
        "Отправить деньги"
      ],
      "to a Taler wallet": [
        "на кошелек Taler"
      ],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "Выводите цифровые деньги на свой мобильный кошелёк или расширение для браузера"
      ],
      "to another bank account": [
        "на другой банковский счет"
      ],
      "Make a wire transfer to an account with known bank account number.": [
        "Сделайте банковский перевод на счет с известным номером банковского счета."
      ],
      "This is a demo": [
        "Это демо-банк"
      ],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s .": [
        "В этой части демонстрации показано как будет работать банк поддерживающий Taler напрямую. Помимо использования собственного банковского счёта, вы также можете просмотреть историю транзакций некоторых %1$s."
      ],
      "Here you will be able to see how a bank that supports Taler directly would work.": [
        "В этой части демонстрации показано как будет работать банк поддерживающий Taler напрямую."
      ],
      "Pending account delete operation": [
        "Ожидание операции удаления счёта"
      ],
      "Pending account update operation": [
        "Ожидание операции обновления счёта"
      ],
      "Pending password update operation": [
        "Ожидание операции обновления пароля"
      ],
      "Pending transaction operation": [
        "Ожидание операции транзакции"
      ],
      "Pending withdrawal operation": [
        "Ожидание операции вывода средств"
      ],
      "Pending cashout operation": [
        "Ожидание операции обналички"
      ],
      "You can complete or cancel the operation in": [
        "Завершить или отменить операцию можно в"
      ],
      "Internal error, please report.": [
        "Внутренняя ошибка, пожалуйста, сообщите."
      ],
      "Preferences": [
        "Настройки"
      ],
      "Welcome": [
        "Добро пожаловать"
      ],
      "Welcome, %1$s": [
        "Добро пожаловать, %1$s"
      ],
      "History of public accounts": [
        "История публичных счетов"
      ],
      "Scan the QR code below to start the withdrawal.": [
        "Отсканируйте QR-код ниже чтобы начать вывод средств."
      ],
      "Operation aborted": [
        "Операция прервана"
      ],
      "The wire transfer to the Payment Service Provider's account was aborted from somewhere else, your balance was not affected.": [
        "Банковский перевод на счет оператора Обменника Taler был прерван, ваш баланс не пострадал."
      ],
      "Go to your wallet now": [
        "Перейти в кошелек"
      ],
      "The operation is marked as selected, but a process during the withdrawal failed": [
        "Операция помечена как «выбранная», но какой-то шаг в выводе средств не удался"
      ],
      "The account was selected, but no withdrawal reserve ID was found.": [
        "Счёт выбран, но идентификатор вывода средств не найден."
      ],
      "There is a withdrawal reserve ID but no account has been selected or the selected account is invalid.": [
        "Есть идентификатор вывода средств, но счёт не был выбран или выбранный счёт недействителен."
      ],
      "A withdrawal reserve ID was not found and the no account has been selected.": [
        ""
      ],
      "Operation not found": [
        ""
      ],
      "This process is not known to the server. The process ID is incorrect or the server has deleted the process information before it arrived here.": [
        ""
      ],
      "Continue to dashboard": [
        ""
      ],
      "Confirmation codes are numerical, possibly beginning with 'T-.'": [
        ""
      ],
      "No cashout was found. The cashout process has probably already been aborted.": [
        ""
      ],
      "Challenge not found.": [
        ""
      ],
      "This user is not authorized to complete this challenge.": [
        ""
      ],
      "Too many attempts, try another code.": [
        ""
      ],
      "The confirmation code is wrong, try again.": [
        ""
      ],
      "The operation expired.": [
        ""
      ],
      "The operation failed.": [
        ""
      ],
      "The operation needs another confirmation to complete.": [
        ""
      ],
      "Confirm the operation": [
        "Подтвердить операцию"
      ],
      "This operation is protected with second factor authentication. In order to complete it we need to verify your identity using the authentication channel you provided.": [
        ""
      ],
      "Enter the confirmation code": [
        "Введите код подтверждения"
      ],
      "You should have received a code on your mobile phone.": [
        ""
      ],
      "You should have received a code in your email.": [
        ""
      ],
      "The confirmation code starts with \"%1$s\" followed by numbers.": [
        ""
      ],
      "Confirm": [
        "Подтвердить"
      ],
      "Removing account": [
        "На счёт"
      ],
      "Updating account values": [
        "Обновление значений счетов"
      ],
      "Updating password": [
        "Обновить пароль"
      ],
      "Making a wire transfer": [
        "Сделать банковский перевод"
      ],
      "Confirming withdrawal": [
        "Подтвердите операцию вывода"
      ],
      "Making a cashout": [
        ""
      ],
      "Operation:": [
        "Операция:"
      ],
      "Type": [
        ""
      ],
      "Updating account settings": [
        "Ожидание операции удаления счёта"
      ],
      "Account": [
        "Счета"
      ],
      "To account": [
        "На счёт"
      ],
      "Cashout account": [
        "Нет счёта для выплат"
      ],
      "Email": [
        "Email"
      ],
      "Phone": [
        "Телефон"
      ],
      "Debit threshold": [
        "Дебетировано"
      ],
      "Is this account public?": [
        "Является ли этот счёт общедоступным?"
      ],
      "Enable": [
        ""
      ],
      "Disable": [
        ""
      ],
      "Name": [
        "Название"
      ],
      "Authentication channel": [
        "Требуется аутентификация"
      ],
      "Remove": [
        "удалить"
      ],
      "New password": [
        "Новый пароль"
      ],
      "Challenge details": [
        "Детали подтверждения"
      ],
      "Sent at": [
        "Время отправления"
      ],
      "To phone": [
        "На телефон"
      ],
      "To email": [
        "На email"
      ],
      "Send again": [
        "Отправить ещё раз"
      ],
      "Withdraw reserve ID": [
        "Снять средства"
      ],
      "Cashout is disabled": [
        "Выплата создана"
      ],
      "Cashout should be enabled in the configuration, the conversion rate should be initialized with fee(s), rates and a rounding mode.": [
        ""
      ],
      "Make a wire transfer": [
        "Сделать банковский перевод"
      ],
      "The Withdrawal URI is not valid": [
        "URI вывода недействителен"
      ],
      "Cashout should be enable by configuration and the conversion rate should be initialized with fee, ratio and rounding mode.": [
        ""
      ],
      "Latest cashouts": [
        "Последние обналички"
      ],
      "Created": [
        "Создано"
      ],
      "Total debit": [
        "Всего дебет"
      ],
      "Total credit": [
        "Итого кредит"
      ],
      "Select a section": [
        "Выберите раздел"
      ],
      "Details": [
        "Подробности"
      ],
      "Delete": [
        "Удалить"
      ],
      "Credentials": [
        "Учетные данные"
      ],
      "Cashouts": [
        "Выплаты"
      ],
      "Conversion": [
        "Обменный курс"
      ],
      "Unable to create a cashout": [
        "Не удается создать выплату"
      ],
      "The bank configuration does not support cashout operations.": [
        "Конфигурация банка не поддерживает операции выплаты."
      ],
      "Amount needs to be higher": [
        "должна быть выше из-за комиссий"
      ],
      "It is not possible to cashout less than %1$s": [
        ""
      ],
      "Your account can't cashout less than %1$s": [
        ""
      ],
      "The total transfer to the destination will be zero": [
        "общая сумма перевода в назначенее будет равна нулю"
      ],
      "Cashout created": [
        "Выплата создана"
      ],
      "Duplicated request detected, check if the operation succeeded or try again.": [
        "Обнаружен дубликат запроса, проверьте, успешно ли выполнена операция, или повторите попытку."
      ],
      "The conversion rate was applied incorrectly": [
        "Неправильно применен курс конвертации"
      ],
      "The account does not have sufficient funds": [
        "На счете недостаточно средств"
      ],
      "Missing cashout URI in the profile": [
        "Отсутствующий URI вылат в профиле"
      ],
      "The amount is below the minimum amount permitted.": [
        ""
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.": [
        "Не удалось отправить сообщение с подтверждением, повторите попытку позже или обратитесь к администратору."
      ],
      "The server doesn't support the current TAN channel.": [
        "Этот сервер не поддерживает двухфакторную аутентификацию."
      ],
      "Conversion rate": [
        "Обменный курс"
      ],
      "Balance": [
        "Баланс"
      ],
      "Fee": [
        "Комиссия"
      ],
      "Legal name": [
        ""
      ],
      "If this name doesn't match the account holder's name, your transaction may fail.": [
        ""
      ],
      "No cashout account": [
        "Нет счёта для выплат"
      ],
      "Before being able to cashout to a bank account, you need to complete your profile": [
        "Перед тем, как сделать выплату, вам необходимо заполнить свой профиль"
      ],
      "Currency": [
        ""
      ],
      "Send %1$s": [
        "Отправить %1$s"
      ],
      "Receive %1$s": [
        "Получить %1$s"
      ],
      "Total cost": [
        "Общая стоимость"
      ],
      "Balance left": [
        "Остаток баланса"
      ],
      "Before fee": [
        "Комиссия до"
      ],
      "Total cashout transfer": [
        "Общий сумма перевода выплаты"
      ],
      "Cashout for account %1$s": [
        "Выплата для аккаунта %1$s"
      ],
      "Doesn't have the pattern of an email": [
        "У него нет шаблона электронного письма"
      ],
      "Should start with +": [
        "должен начинаться с +"
      ],
      "A phone number consists of numbers only": [
        "Номер телефона не может иметь ничего, кроме цифр"
      ],
      "Account ID for authentication": [
        "Двухфакторная аутентификация"
      ],
      "Name of the account holder": [
        "имя пользователя счёта"
      ],
      "Internal account": [
        "на другой банковский счет"
      ],
      "If this field is empty, a random account ID will be assigned": [
        "Если пусто, будет присвоен случайный номер счета"
      ],
      "You can copy and share this IBAN number in order to receive wire transfers to your bank account": [
        ""
      ],
      "To be used when second factor authentication is enabled": [
        "Включите двухфакторную аутентификацию"
      ],
      "External account number where the money is going to be sent when doing cashouts": [
        "номер счета, на который будут отправлены деньги при выводе средств"
      ],
      "Max debt": [
        "Максимальная задолженность"
      ],
      "How much the balance can go below zero.": [
        ""
      ],
      "Minimum cashout": [
        "выплаты"
      ],
      "Custom minimum cashout amount for this account.": [
        ""
      ],
      "Public accounts have their balance publicly accessible": [
        "Баланс публичных счётов находится в открытом доступе"
      ],
      "Does this account belong to a Payment Service Provider?": [
        "Является ли этот счёт общедоступным?"
      ],
      "Account updated": [
        "Счёт обновлён"
      ],
      "The rights to change the account are not sufficient": [
        "Недостаточно прав на изменение счёта"
      ],
      "The username was not found": [
        "Имя пользователя не найдено"
      ],
      "You can't change the legal name, please contact the your account administrator.": [
        "Вы не можете изменить официальное имя, обратитесь к администратору вашей учетной записи."
      ],
      "You can't change the debt limit, please contact the your account administrator.": [
        "Вы не можете изменить лимит задолженности, обратитесь к администратору аккаунта."
      ],
      "You can't change the cashout address, please contact the your account administrator.": [
        "Вы не можете изменить адрес для вывода средств, пожалуйста, свяжитесь с администратором вашего аккаунта."
      ],
      "Account \"%1$s\"": [
        "Счет \"%1$s\""
      ],
      "Removed": [
        "удалить"
      ],
      "This account can't be used.": [
        ""
      ],
      "Change details": [
        "Изменение реквизитов"
      ],
      "Update": [
        "Обновить"
      ],
      "Merchant integration": [
        "Регистрация счёта"
      ],
      "Use this information to link your Taler Merchant Backoffice account with the current bank account. You can start by copying the values, then go to your merchant backoffice service provider, login into your account and look for the \"import\" button in the \"bank account\" section.": [
        ""
      ],
      "Account type": [
        "Удаление счёта"
      ],
      "Method to use for wire transfer.": [
        "Сделать банковский перевод"
      ],
      "IBAN": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Account name": [
        "Обновление счёта"
      ],
      "Bank host where the service is located.": [
        ""
      ],
      "Bank account identifier for wire transfers.": [
        "Идентификация счета для банковского перевода"
      ],
      "Address": [
        ""
      ],
      "Owner's name": [
        "Имя пользователя"
      ],
      "Legal name of the person holding the account.": [
        "имя владельца счёта"
      ],
      "Account info URL": [
        "Учётная запись не найдена"
      ],
      "From where the merchant can download information about incoming wire transfers to this account.": [
        ""
      ],
      "Copy": [
        ""
      ],
      "Repeated password doesn't match": [
        "пароль не совпадает"
      ],
      "Password changed": [
        "Пароль изменен"
      ],
      "Not authorized to change the password, maybe the session is invalid.": [
        ""
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.": [
        ""
      ],
      "Your current password doesn't match, can't change to a new password.": [
        ""
      ],
      "Update password": [
        "Обновить пароль"
      ],
      "Current password": [
        "Текущий пароль"
      ],
      "Your current password, for security": [
        ""
      ],
      "Type it again": [
        "Введите его ещё раз"
      ],
      "Repeat the same password": [
        "повторите этот же пароль"
      ],
      "Change": [
        "Изменить"
      ],
      "Accounts": [
        "Счета"
      ],
      "Create account": [
        "Создать учётную запись"
      ],
      "Actions": [
        "Действия"
      ],
      "Unknown": [
        "неизвестно"
      ],
      "Change password": [
        "изменить пароль"
      ],
      "Querying for the current stats failed": [
        ""
      ],
      "The request parameters are wrong": [
        ""
      ],
      "The user is unauthorized": [
        "Имя пользователя не найдено"
      ],
      "Querying for the previous stats failed": [
        ""
      ],
      "Transaction volume report": [
        ""
      ],
      "Last hour": [
        "Последний час"
      ],
      "Previous day": [
        ""
      ],
      "Last month": [
        "Последний месяц"
      ],
      "Last year": [
        "Последний год"
      ],
      "Last Year": [
        "Прошлый год"
      ],
      "Trading volume from %1$s to %2$s": [
        "Объем торгов на %1$s по сравнению с %2$s"
      ],
      "Cashin": [
        "Внесения"
      ],
      "Transferred from an external account to an account in this bank.": [
        ""
      ],
      "Transferred from an account in this bank to an external account.": [
        "Сделайте банковский перевод на счет с известным номером банковского счета."
      ],
      "Payin": [
        "Отплата"
      ],
      "Transferred from an account to a Taler exchange.": [
        ""
      ],
      "Payout": [
        "Выплата"
      ],
      "Transferred from a Taler exchange to another account.": [
        "Счет оператора Обменника Taler"
      ],
      "Download stats as CSV": [
        "скачать статистику в формате CSV"
      ],
      "previous": [
        ""
      ],
      "Decreased by": [
        "Уменьшилось на"
      ],
      "Increased by": [
        "Увеличение на"
      ],
      "Account created with password \"%1$s\".": [
        ""
      ],
      "Server replied that phone or email is invalid": [
        ""
      ],
      "The rights to perform the operation are not sufficient": [
        ""
      ],
      "Account username is already taken": [
        ""
      ],
      "Account id is already taken": [
        ""
      ],
      "Bank ran out of bonus credit.": [
        ""
      ],
      "Account username can't be used because is reserved": [
        ""
      ],
      "Can't create accounts": [
        ""
      ],
      "Only system admin can create accounts.": [
        ""
      ],
      "New bank account": [
        "Новый бизнес счёт"
      ],
      "Create": [
        "Создать"
      ],
      "Download bank stats": [
        "Скачивать статистику банка"
      ],
      "Include hour metric": [
        "Включить часовую метрику"
      ],
      "Include day metric": [
        "Включить дневную метрику"
      ],
      "Include month metric": [
        "Включить месячную метрику"
      ],
      "Include year metric": [
        "Включить годовую метрику"
      ],
      "Include table header": [
        "Включить заголовок таблицы"
      ],
      "Add previous metric for compare": [
        "Добавить предыдущую метрику для сравнения"
      ],
      "Fail on first error": [
        "Сбой при первой ошибке"
      ],
      "Download": [
        "Скачивать"
      ],
      "downloading... %1$s": [
        "скачивание... %1$s"
      ],
      "Download completed": [
        "Скачивание завершено"
      ],
      "Click here to save the file in your computer.": [
        "Нажмите здесь, чтобы сохранить файл на своем компьютере"
      ],
      "Can't delete the account": [
        ""
      ],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.": [
        ""
      ],
      "Account removed": [
        ""
      ],
      "No enough permission to delete the account.": [
        ""
      ],
      "The username was not found.": [
        ""
      ],
      "Can't delete a reserved username.": [
        ""
      ],
      "Can't delete an account with balance different than zero.": [
        ""
      ],
      "Name doesn't match": [
        "пароль не совпадает"
      ],
      "You are going to remove the account": [
        ""
      ],
      "This step can't be undone.": [
        ""
      ],
      "Deleting account \"%1$s\"": [
        "Удаление счёта \"%1$s\""
      ],
      "Verification": [
        "Проверка"
      ],
      "Enter the account name that is going to be deleted": [
        ""
      ],
      "loading...": [
        "скачивание... %1$s"
      ],
      "only admin can setup conversion": [
        ""
      ],
      "Wrong credentials": [
        "Неверные учетные данные для «%1$s» ‎"
      ],
      "Conversion is disabled": [
        "Обменный курс"
      ],
      "Config cashout": [
        "Выплата"
      ],
      "Config cashin": [
        "Внесения"
      ],
      "Cashin ratio": [
        "Внесения"
      ],
      "Cashout ratio": [
        "Выплата создана"
      ],
      "Bad ratios": [
        ""
      ],
      "One of the ratios should be higher or equal than 1 an the other should be lower or equal than 1.": [
        ""
      ],
      "Initial amount": [
        "Максимальная сумма вывода"
      ],
      "Use it to test how the conversion will affect the amount.": [
        ""
      ],
      "Sending to this bank": [
        ""
      ],
      "Converted": [
        "Обменный курс"
      ],
      "Cashin after fee": [
        ""
      ],
      "Sending from this bank": [
        ""
      ],
      "Cashout after fee": [
        "Выплата создана"
      ],
      "Bad configuration": [
        ""
      ],
      "This configuration allows users to cash out more of what has been cashed in.": [
        ""
      ],
      "Rnvalid": [
        "недействительно"
      ],
      "Minimum amount": [
        ""
      ],
      "Only cashout operation above this threshold will be allowed": [
        ""
      ],
      "Ratio": [
        ""
      ],
      "Conversion ratio between currencies": [
        "Обменный курс"
      ],
      "Example conversion": [
        ""
      ],
      "1 %1$s will be converted into %2$s %3$s": [
        ""
      ],
      "Rounding value": [
        ""
      ],
      "Smallest difference between two amounts after the ratio is applied.": [
        ""
      ],
      "Rounding mode": [
        ""
      ],
      "Zero": [
        ""
      ],
      "Amount will be round below to the largest possible value smaller than the input.": [
        ""
      ],
      "Up": [
        ""
      ],
      "Amount will be round up to the smallest possible value larger than the input.": [
        ""
      ],
      "Nearest": [
        ""
      ],
      "Amount will be round to the closest possible value.": [
        ""
      ],
      "Examples": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.1": [
        ""
      ],
      "Given the rounding value of 0.1 the possible values closest to 1.24 are: 1.1, 1.2, 1.3, 1.4.": [
        ""
      ],
      "With the \"zero\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.1": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.3": [
        ""
      ],
      "Given the rounding value of 0.3 the possible values closest to 1.24 are: 0.9, 1.2, 1.5, 1.8.": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.5": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.3": [
        ""
      ],
      "Amount to be deducted before amount is credited.": [
        ""
      ],
      "Cashout id should be a number": [
        ""
      ],
      "This cashout not found. Maybe already aborted.": [
        ""
      ],
      "Cashout detail": [
        "Подробности обналичивания"
      ],
      "Debited": [
        "Дебетировано"
      ],
      "Credited": [
        "Кредитировано"
      ],
      "Welcome to %1$s!": [
        "Добро пожаловать в %1$s!"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=3; plural=n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2;",
  "lang": "ru",
  "completeness": 69
};

strings['it'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "it"
      },
      "Show withdrawal confirmation": [
        "Questo ritiro è stato annullato!"
      ],
      "Withdraw without setting amount": [
        ""
      ],
      "Show copy account letter": [
        ""
      ],
      "Show demo description": [
        ""
      ],
      "Show install wallet first": [
        "Questo ritiro è stato annullato!"
      ],
      "Remove password length validation on registration": [
        ""
      ],
      "Show debug info": [
        ""
      ],
      "Operation failed, please report": [
        "Registrazione"
      ],
      "Request timeout": [
        ""
      ],
      "Request throttled": [
        ""
      ],
      "Malformed response": [
        ""
      ],
      "Network error": [
        ""
      ],
      "Unexpected request error": [
        ""
      ],
      "Unexpected error": [
        ""
      ],
      "An IBAN consists of capital letters and numbers only": [
        ""
      ],
      "IBAN numbers have more that 4 digits": [
        ""
      ],
      "IBAN numbers have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Use letters, numbers or any of these characters: - . _ ~": [
        ""
      ],
      "Required": [
        ""
      ],
      "Not valid": [
        ""
      ],
      "Does not follow the pattern": [
        ""
      ],
      "The request was invalid or the payto://-URI used unacceptable features.": [
        ""
      ],
      "Not enough permission to complete the operation.": [
        "La banca sta creando l'operazione..."
      ],
      "The bank administrator cannot be the transfer creditor.": [
        ""
      ],
      "The destination account \"%1$s\" was not found.": [
        "Lista conti pubblici non trovata."
      ],
      "The origin and the destination of the transfer can't be the same.": [
        ""
      ],
      "Your balance is not sufficient for the operation.": [
        ""
      ],
      "The origin account \"%1$s\" was not found.": [
        "Lista conti pubblici non trovata."
      ],
      "The attempt to create the transaction has failed. Please try again.": [
        ""
      ],
      "The wire transfer was successfully completed!": [
        "Bonifico"
      ],
      "Input wire transfer detail": [
        "Bonifico"
      ],
      "Using a form": [
        ""
      ],
      "A special URI that specifies the amount to be transferred and the destination account.": [
        ""
      ],
      "QR code": [
        ""
      ],
      "If your device has a camera, you can import a payto:// URI from a QR code.": [
        ""
      ],
      "Cashout": [
        ""
      ],
      "Recipient": [
        ""
      ],
      "ID of the recipient's account": [
        "Storico dei conti pubblici"
      ],
      "username": [
        ""
      ],
      "IBAN of the recipient's account": [
        ""
      ],
      "Transfer subject": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "Subject": [
        "Soggetto"
      ],
      "Some text to identify the transfer": [
        ""
      ],
      "Amount": [
        "Importo"
      ],
      "Amount to transfer": [
        "Somma da trasferire"
      ],
      "Payto URI:": [
        ""
      ],
      "Uniform resource identifier of the target account": [
        ""
      ],
      "payto://x-taler-bank/[bank-host]/[receiver-account]?message=[subject]&amount=[%1$s:X.Y]": [
        ""
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        ""
      ],
      "The maximum amount for a wire transfer is %1$s": [
        ""
      ],
      "Cost": [
        ""
      ],
      "Cancel": [
        ""
      ],
      "Send": [
        ""
      ],
      "The target type is unknown, use \"%1$s\"": [
        ""
      ],
      "Only \"x-taler-bank\" target are supported": [
        ""
      ],
      "Only this host is allowed. Use \"%1$s\"": [
        ""
      ],
      "Account name is missing": [
        "Importo"
      ],
      "Only \"IBAN\" target are supported": [
        ""
      ],
      "Missing \"amount\" parameter to specify the amount to be transferred": [
        ""
      ],
      "The \"amount\" parameter is not valid": [
        "Questo ritiro è stato annullato!"
      ],
      "\"message\" parameters to specify a reference text for the transfer are missing": [
        ""
      ],
      "The only currency allowed is \"%1$s\"": [
        ""
      ],
      "You cannot transfer an amount of zero.": [
        ""
      ],
      "The balance is not sufficient": [
        ""
      ],
      "Please enter a longer subject": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "Currently, the bank is not accepting new registrations!": [
        ""
      ],
      "The name is missing": [
        ""
      ],
      "Missing username": [
        ""
      ],
      "Missing password": [
        ""
      ],
      "The password should be longer than 8 letters": [
        ""
      ],
      "The passwords do not match": [
        ""
      ],
      "Server replied with invalid phone or email.": [
        ""
      ],
      "You are not authorised to create this account.": [
        ""
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        ""
      ],
      "That username can't be used because is reserved.": [
        ""
      ],
      "That username is already taken.": [
        ""
      ],
      "That account ID is already taken.": [
        ""
      ],
      "No information for the selected authentication channel.": [
        ""
      ],
      "Authentication channel is not supported.": [
        ""
      ],
      "Only an administrator is allowed to set the debt limit.": [
        ""
      ],
      "Only the administrator can change the minimum cashout limit.": [
        ""
      ],
      "Only admin can create accounts with second factor authentication.": [
        ""
      ],
      "The password is too short.": [
        ""
      ],
      "The password is too long.": [
        ""
      ],
      "Account registration": [
        ""
      ],
      "Login username": [
        ""
      ],
      "Password": [
        ""
      ],
      "Use a strong password: 8 characters minimum, don't use any public information related to you (names, birthday, phone number, etc...) and mix lowercase, uppercase, symbols and numbers": [
        ""
      ],
      "Repeat password": [
        ""
      ],
      "Full name": [
        ""
      ],
      "Register": [
        "Registrati"
      ],
      "Create a random temporary user": [
        ""
      ],
      "Wrong credentials for \"%1$s\"": [
        "Credenziali invalide."
      ],
      "Account not found": [
        ""
      ],
      "Username": [
        ""
      ],
      "Username of the account": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "Password of the account": [
        "Storico dei conti pubblici"
      ],
      "Check": [
        ""
      ],
      "Log in": [
        ""
      ],
      "Transactions history": [
        ""
      ],
      "No transactions yet.": [
        "Ancora nessuna transazione."
      ],
      "You can make a transfer or a withdrawal to your wallet.": [
        ""
      ],
      "Date": [
        "Data"
      ],
      "Counterpart": [
        "Conto corrente"
      ],
      "sent": [
        ""
      ],
      "received": [
        ""
      ],
      "Invalid value": [
        ""
      ],
      "to": [
        ""
      ],
      "from": [
        ""
      ],
      "First page": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire transfer completed!": [
        "Bonifico"
      ],
      "The withdrawal has been aborted previously and can't be confirmed": [
        ""
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.": [
        ""
      ],
      "The operation ID is invalid.": [
        "L'ID dell'operazione non è valido."
      ],
      "The operation was not found.": [
        "L'operazione non è stata trovata."
      ],
      "The starting withdrawal amount and the confirmation amount differs.": [
        ""
      ],
      "The bank requires a bank account which has not been specified yet.": [
        ""
      ],
      "The reserve operation has been confirmed previously and can't be aborted": [
        ""
      ],
      "Confirm the withdrawal operation": [
        "Conferma il ritiro"
      ],
      "Wire transfer details": [
        "Bonifico"
      ],
      "Payment Service Provider's account": [
        ""
      ],
      "Payment Service Provider's account number": [
        ""
      ],
      "Payment Service Provider's name": [
        ""
      ],
      "Payment Service Provider's account bank hostname": [
        ""
      ],
      "Payment Service Provider's account id": [
        ""
      ],
      "Payment Service Provider's account address": [
        ""
      ],
      "No amount has yet been determined.": [
        ""
      ],
      "Transfer": [
        ""
      ],
      "Authentication required": [
        ""
      ],
      "This operation was created with another username": [
        "Lista conti pubblici non trovata."
      ],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.": [
        ""
      ],
      "The operation was rejected due to insufficient funds.": [
        ""
      ],
      "Withdrawal confirmed": [
        "Questo ritiro è stato annullato!"
      ],
      "The wire transfer to the Payment Service Provider has been initiated. You will shortly receive the requested amount in your Taler wallet.": [
        ""
      ],
      "Do not show this again": [
        ""
      ],
      "Close": [
        ""
      ],
      "If you have a Taler wallet installed on this device": [
        ""
      ],
      "Your wallet will display the details of the transaction including the fees (if applicable). If you do not yet have a wallet, please follow the instructions": [
        ""
      ],
      "on this page": [
        ""
      ],
      "Withdraw": [
        "Prelevare"
      ],
      "In case you have a Taler wallet on another device": [
        ""
      ],
      "Scan the QR below to start the withdrawal.": [
        "Chiudi il ritiro Taler"
      ],
      "There is an operation already pending": [
        "Questo ritiro è stato annullato!"
      ],
      "Complete the operation in": [
        "Conferma il ritiro"
      ],
      "this page": [
        ""
      ],
      "Invalid": [
        ""
      ],
      "Balance is not enough": [
        ""
      ],
      "The server replied with an invalid taler://withdraw URI": [
        ""
      ],
      "Withdraw URI: %1$s": [
        "Prelevare"
      ],
      "The operation was rejected due to insufficient funds": [
        ""
      ],
      "Current balance is %1$s": [
        ""
      ],
      "You can withdraw up to %1$s": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Use your Taler wallet": [
        ""
      ],
      "After using your wallet you will need to authorize or cancel the operation on this site.": [
        ""
      ],
      "You need a Taler wallet": [
        "Ritira contante nel portafoglio Taler"
      ],
      "If you don't have one yet you can follow the instruction in": [
        ""
      ],
      "Send money": [
        ""
      ],
      "to a Taler wallet": [
        "Ritira contante nel portafoglio Taler"
      ],
      "Withdraw digital money into your mobile wallet or browser extension": [
        ""
      ],
      "to another bank account": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "Make a wire transfer to an account with known bank account number.": [
        ""
      ],
      "This is a demo": [
        ""
      ],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s .": [
        ""
      ],
      "Here you will be able to see how a bank that supports Taler directly would work.": [
        ""
      ],
      "Pending account delete operation": [
        ""
      ],
      "Pending account update operation": [
        ""
      ],
      "Pending password update operation": [
        ""
      ],
      "Pending transaction operation": [
        ""
      ],
      "Pending withdrawal operation": [
        ""
      ],
      "Pending cashout operation": [
        ""
      ],
      "You can complete or cancel the operation in": [
        ""
      ],
      "Internal error, please report.": [
        "Registrazione"
      ],
      "Preferences": [
        ""
      ],
      "Welcome": [
        ""
      ],
      "Welcome, %1$s": [
        ""
      ],
      "History of public accounts": [
        "Storico dei conti pubblici"
      ],
      "Scan the QR code below to start the withdrawal.": [
        "Chiudi il ritiro Taler"
      ],
      "Operation aborted": [
        ""
      ],
      "The wire transfer to the Payment Service Provider's account was aborted from somewhere else, your balance was not affected.": [
        ""
      ],
      "Go to your wallet now": [
        ""
      ],
      "The operation is marked as selected, but a process during the withdrawal failed": [
        ""
      ],
      "The account was selected, but no withdrawal reserve ID was found.": [
        ""
      ],
      "There is a withdrawal reserve ID but no account has been selected or the selected account is invalid.": [
        ""
      ],
      "A withdrawal reserve ID was not found and the no account has been selected.": [
        ""
      ],
      "Operation not found": [
        ""
      ],
      "This process is not known to the server. The process ID is incorrect or the server has deleted the process information before it arrived here.": [
        ""
      ],
      "Continue to dashboard": [
        ""
      ],
      "Confirmation codes are numerical, possibly beginning with 'T-.'": [
        ""
      ],
      "No cashout was found. The cashout process has probably already been aborted.": [
        ""
      ],
      "Challenge not found.": [
        ""
      ],
      "This user is not authorized to complete this challenge.": [
        ""
      ],
      "Too many attempts, try another code.": [
        ""
      ],
      "The confirmation code is wrong, try again.": [
        ""
      ],
      "The operation expired.": [
        ""
      ],
      "The operation failed.": [
        "Operazione non riuscita."
      ],
      "The operation needs another confirmation to complete.": [
        ""
      ],
      "Confirm the operation": [
        "Conferma il ritiro"
      ],
      "This operation is protected with second factor authentication. In order to complete it we need to verify your identity using the authentication channel you provided.": [
        ""
      ],
      "Enter the confirmation code": [
        ""
      ],
      "You should have received a code on your mobile phone.": [
        ""
      ],
      "You should have received a code in your email.": [
        ""
      ],
      "The confirmation code starts with \"%1$s\" followed by numbers.": [
        ""
      ],
      "Confirm": [
        "Confermare"
      ],
      "Removing account": [
        ""
      ],
      "Updating account values": [
        "Aggiornamento dei valori del conto"
      ],
      "Updating password": [
        ""
      ],
      "Making a wire transfer": [
        "Chiudi il bonifico"
      ],
      "Confirming withdrawal": [
        "Conferma il ritiro"
      ],
      "Making a cashout": [
        ""
      ],
      "Operation:": [
        ""
      ],
      "Type": [
        ""
      ],
      "Updating account settings": [
        ""
      ],
      "Account": [
        "Importo"
      ],
      "To account": [
        ""
      ],
      "Cashout account": [
        "Storico dei conti pubblici"
      ],
      "Email": [
        ""
      ],
      "Phone": [
        ""
      ],
      "Debit threshold": [
        ""
      ],
      "Is this account public?": [
        ""
      ],
      "Enable": [
        ""
      ],
      "Disable": [
        ""
      ],
      "Name": [
        ""
      ],
      "Authentication channel": [
        ""
      ],
      "Remove": [
        ""
      ],
      "New password": [
        ""
      ],
      "Challenge details": [
        ""
      ],
      "Sent at": [
        ""
      ],
      "To phone": [
        ""
      ],
      "To email": [
        ""
      ],
      "Send again": [
        ""
      ],
      "Withdraw reserve ID": [
        "Prelevare"
      ],
      "Cashout is disabled": [
        ""
      ],
      "Cashout should be enabled in the configuration, the conversion rate should be initialized with fee(s), rates and a rounding mode.": [
        ""
      ],
      "Make a wire transfer": [
        "Chiudi il bonifico"
      ],
      "The Withdrawal URI is not valid": [
        "Questo ritiro è stato annullato!"
      ],
      "Cashout should be enable by configuration and the conversion rate should be initialized with fee, ratio and rounding mode.": [
        ""
      ],
      "Latest cashouts": [
        "Ultime transazioni:"
      ],
      "Created": [
        ""
      ],
      "Total debit": [
        ""
      ],
      "Total credit": [
        ""
      ],
      "Select a section": [
        ""
      ],
      "Details": [
        ""
      ],
      "Delete": [
        ""
      ],
      "Credentials": [
        "Credenziali"
      ],
      "Cashouts": [
        "Incassi (Cashout)"
      ],
      "Conversion": [
        "Cambio"
      ],
      "Unable to create a cashout": [
        ""
      ],
      "The bank configuration does not support cashout operations.": [
        ""
      ],
      "Amount needs to be higher": [
        "Somma da ritirare"
      ],
      "It is not possible to cashout less than %1$s": [
        ""
      ],
      "Your account can't cashout less than %1$s": [
        ""
      ],
      "The total transfer to the destination will be zero": [
        ""
      ],
      "Cashout created": [
        ""
      ],
      "Duplicated request detected, check if the operation succeeded or try again.": [
        ""
      ],
      "The conversion rate was applied incorrectly": [
        ""
      ],
      "The account does not have sufficient funds": [
        ""
      ],
      "Missing cashout URI in the profile": [
        ""
      ],
      "The amount is below the minimum amount permitted.": [
        ""
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.": [
        ""
      ],
      "The server doesn't support the current TAN channel.": [
        ""
      ],
      "Conversion rate": [
        ""
      ],
      "Balance": [
        ""
      ],
      "Fee": [
        ""
      ],
      "Legal name": [
        ""
      ],
      "If this name doesn't match the account holder's name, your transaction may fail.": [
        ""
      ],
      "No cashout account": [
        ""
      ],
      "Before being able to cashout to a bank account, you need to complete your profile": [
        ""
      ],
      "Currency": [
        ""
      ],
      "Send %1$s": [
        ""
      ],
      "Receive %1$s": [
        ""
      ],
      "Total cost": [
        ""
      ],
      "Balance left": [
        ""
      ],
      "Before fee": [
        ""
      ],
      "Total cashout transfer": [
        ""
      ],
      "Cashout for account %1$s": [
        ""
      ],
      "Doesn't have the pattern of an email": [
        ""
      ],
      "Should start with +": [
        ""
      ],
      "A phone number consists of numbers only": [
        ""
      ],
      "Account ID for authentication": [
        ""
      ],
      "Name of the account holder": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "Internal account": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "If this field is empty, a random account ID will be assigned": [
        ""
      ],
      "You can copy and share this IBAN number in order to receive wire transfers to your bank account": [
        ""
      ],
      "To be used when second factor authentication is enabled": [
        ""
      ],
      "External account number where the money is going to be sent when doing cashouts": [
        ""
      ],
      "Max debt": [
        ""
      ],
      "How much the balance can go below zero.": [
        ""
      ],
      "Minimum cashout": [
        "Ultime transazioni:"
      ],
      "Custom minimum cashout amount for this account.": [
        ""
      ],
      "Public accounts have their balance publicly accessible": [
        ""
      ],
      "Does this account belong to a Payment Service Provider?": [
        ""
      ],
      "Account updated": [
        ""
      ],
      "The rights to change the account are not sufficient": [
        ""
      ],
      "The username was not found": [
        ""
      ],
      "You can't change the legal name, please contact the your account administrator.": [
        ""
      ],
      "You can't change the debt limit, please contact the your account administrator.": [
        ""
      ],
      "You can't change the cashout address, please contact the your account administrator.": [
        ""
      ],
      "Account \"%1$s\"": [
        ""
      ],
      "Removed": [
        ""
      ],
      "This account can't be used.": [
        ""
      ],
      "Change details": [
        ""
      ],
      "Update": [
        ""
      ],
      "Merchant integration": [
        ""
      ],
      "Use this information to link your Taler Merchant Backoffice account with the current bank account. You can start by copying the values, then go to your merchant backoffice service provider, login into your account and look for the \"import\" button in the \"bank account\" section.": [
        ""
      ],
      "Account type": [
        "Importo"
      ],
      "Method to use for wire transfer.": [
        "Chiudi il bonifico"
      ],
      "IBAN": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Account name": [
        "Importo"
      ],
      "Bank host where the service is located.": [
        ""
      ],
      "Bank account identifier for wire transfers.": [
        ""
      ],
      "Address": [
        "indirizzo Payto"
      ],
      "Owner's name": [
        ""
      ],
      "Legal name of the person holding the account.": [
        ""
      ],
      "Account info URL": [
        "Lista conti pubblici non trovata."
      ],
      "From where the merchant can download information about incoming wire transfers to this account.": [
        ""
      ],
      "Copy": [
        ""
      ],
      "Repeated password doesn't match": [
        ""
      ],
      "Password changed": [
        ""
      ],
      "Not authorized to change the password, maybe the session is invalid.": [
        ""
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.": [
        ""
      ],
      "Your current password doesn't match, can't change to a new password.": [
        ""
      ],
      "Update password": [
        ""
      ],
      "Current password": [
        ""
      ],
      "Your current password, for security": [
        ""
      ],
      "Type it again": [
        ""
      ],
      "Repeat the same password": [
        ""
      ],
      "Change": [
        ""
      ],
      "Accounts": [
        "Importo"
      ],
      "Create account": [
        ""
      ],
      "Actions": [
        ""
      ],
      "Unknown": [
        ""
      ],
      "Change password": [
        ""
      ],
      "Querying for the current stats failed": [
        ""
      ],
      "The request parameters are wrong": [
        ""
      ],
      "The user is unauthorized": [
        ""
      ],
      "Querying for the previous stats failed": [
        ""
      ],
      "Transaction volume report": [
        ""
      ],
      "Last hour": [
        ""
      ],
      "Previous day": [
        ""
      ],
      "Last month": [
        ""
      ],
      "Last year": [
        ""
      ],
      "Last Year": [
        ""
      ],
      "Trading volume from %1$s to %2$s": [
        ""
      ],
      "Cashin": [
        ""
      ],
      "Transferred from an external account to an account in this bank.": [
        ""
      ],
      "Transferred from an account in this bank to an external account.": [
        ""
      ],
      "Payin": [
        ""
      ],
      "Transferred from an account to a Taler exchange.": [
        ""
      ],
      "Payout": [
        ""
      ],
      "Transferred from a Taler exchange to another account.": [
        ""
      ],
      "Download stats as CSV": [
        ""
      ],
      "previous": [
        ""
      ],
      "Decreased by": [
        ""
      ],
      "Increased by": [
        ""
      ],
      "Account created with password \"%1$s\".": [
        ""
      ],
      "Server replied that phone or email is invalid": [
        ""
      ],
      "The rights to perform the operation are not sufficient": [
        ""
      ],
      "Account username is already taken": [
        ""
      ],
      "Account id is already taken": [
        ""
      ],
      "Bank ran out of bonus credit.": [
        ""
      ],
      "Account username can't be used because is reserved": [
        ""
      ],
      "Can't create accounts": [
        ""
      ],
      "Only system admin can create accounts.": [
        ""
      ],
      "New bank account": [
        "Trasferisci fondi a un altro conto di questa banca:"
      ],
      "Create": [
        ""
      ],
      "Download bank stats": [
        ""
      ],
      "Include hour metric": [
        ""
      ],
      "Include day metric": [
        ""
      ],
      "Include month metric": [
        ""
      ],
      "Include year metric": [
        ""
      ],
      "Include table header": [
        ""
      ],
      "Add previous metric for compare": [
        ""
      ],
      "Fail on first error": [
        ""
      ],
      "Download": [
        ""
      ],
      "downloading... %1$s": [
        ""
      ],
      "Download completed": [
        ""
      ],
      "Click here to save the file in your computer.": [
        ""
      ],
      "Can't delete the account": [
        ""
      ],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.": [
        ""
      ],
      "Account removed": [
        ""
      ],
      "No enough permission to delete the account.": [
        ""
      ],
      "The username was not found.": [
        ""
      ],
      "Can't delete a reserved username.": [
        ""
      ],
      "Can't delete an account with balance different than zero.": [
        ""
      ],
      "Name doesn't match": [
        ""
      ],
      "You are going to remove the account": [
        ""
      ],
      "This step can't be undone.": [
        ""
      ],
      "Deleting account \"%1$s\"": [
        ""
      ],
      "Verification": [
        ""
      ],
      "Enter the account name that is going to be deleted": [
        ""
      ],
      "loading...": [
        ""
      ],
      "only admin can setup conversion": [
        ""
      ],
      "Wrong credentials": [
        "Credenziali invalide."
      ],
      "Conversion is disabled": [
        ""
      ],
      "Config cashout": [
        "Ultime transazioni:"
      ],
      "Config cashin": [
        ""
      ],
      "Cashin ratio": [
        ""
      ],
      "Cashout ratio": [
        ""
      ],
      "Bad ratios": [
        ""
      ],
      "One of the ratios should be higher or equal than 1 an the other should be lower or equal than 1.": [
        ""
      ],
      "Initial amount": [
        "Questo ritiro è stato annullato!"
      ],
      "Use it to test how the conversion will affect the amount.": [
        ""
      ],
      "Sending to this bank": [
        ""
      ],
      "Converted": [
        ""
      ],
      "Cashin after fee": [
        ""
      ],
      "Sending from this bank": [
        ""
      ],
      "Cashout after fee": [
        ""
      ],
      "Bad configuration": [
        ""
      ],
      "This configuration allows users to cash out more of what has been cashed in.": [
        ""
      ],
      "Rnvalid": [
        ""
      ],
      "Minimum amount": [
        ""
      ],
      "Only cashout operation above this threshold will be allowed": [
        ""
      ],
      "Ratio": [
        ""
      ],
      "Conversion ratio between currencies": [
        ""
      ],
      "Example conversion": [
        ""
      ],
      "1 %1$s will be converted into %2$s %3$s": [
        ""
      ],
      "Rounding value": [
        ""
      ],
      "Smallest difference between two amounts after the ratio is applied.": [
        ""
      ],
      "Rounding mode": [
        ""
      ],
      "Zero": [
        ""
      ],
      "Amount will be round below to the largest possible value smaller than the input.": [
        ""
      ],
      "Up": [
        ""
      ],
      "Amount will be round up to the smallest possible value larger than the input.": [
        ""
      ],
      "Nearest": [
        ""
      ],
      "Amount will be round to the closest possible value.": [
        ""
      ],
      "Examples": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.1": [
        ""
      ],
      "Given the rounding value of 0.1 the possible values closest to 1.24 are: 1.1, 1.2, 1.3, 1.4.": [
        ""
      ],
      "With the \"zero\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.1": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.3": [
        ""
      ],
      "Given the rounding value of 0.3 the possible values closest to 1.24 are: 0.9, 1.2, 1.5, 1.8.": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.5": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.3": [
        ""
      ],
      "Amount to be deducted before amount is credited.": [
        ""
      ],
      "Cashout id should be a number": [
        ""
      ],
      "This cashout not found. Maybe already aborted.": [
        ""
      ],
      "Cashout detail": [
        ""
      ],
      "Debited": [
        ""
      ],
      "Credited": [
        ""
      ],
      "Welcome to %1$s!": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "it",
  "completeness": 15
};

strings['fr'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n > 1;",
        "lang": "fr"
      },
      "Show withdrawal confirmation": [
        ""
      ],
      "Withdraw without setting amount": [
        ""
      ],
      "Show copy account letter": [
        ""
      ],
      "Show demo description": [
        ""
      ],
      "Show install wallet first": [
        ""
      ],
      "Remove password length validation on registration": [
        ""
      ],
      "Show debug info": [
        ""
      ],
      "Operation failed, please report": [
        "L'opération a échoué, veuillez le signaler"
      ],
      "Request timeout": [
        ""
      ],
      "Request throttled": [
        ""
      ],
      "Malformed response": [
        ""
      ],
      "Network error": [
        "Erreur réseau"
      ],
      "Unexpected request error": [
        ""
      ],
      "Unexpected error": [
        ""
      ],
      "An IBAN consists of capital letters and numbers only": [
        ""
      ],
      "IBAN numbers have more that 4 digits": [
        ""
      ],
      "IBAN numbers have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Use letters, numbers or any of these characters: - . _ ~": [
        ""
      ],
      "Required": [
        ""
      ],
      "Not valid": [
        ""
      ],
      "Does not follow the pattern": [
        ""
      ],
      "The request was invalid or the payto://-URI used unacceptable features.": [
        ""
      ],
      "Not enough permission to complete the operation.": [
        ""
      ],
      "The bank administrator cannot be the transfer creditor.": [
        ""
      ],
      "The destination account \"%1$s\" was not found.": [
        ""
      ],
      "The origin and the destination of the transfer can't be the same.": [
        ""
      ],
      "Your balance is not sufficient for the operation.": [
        ""
      ],
      "The origin account \"%1$s\" was not found.": [
        ""
      ],
      "The attempt to create the transaction has failed. Please try again.": [
        ""
      ],
      "The wire transfer was successfully completed!": [
        ""
      ],
      "Input wire transfer detail": [
        ""
      ],
      "Using a form": [
        ""
      ],
      "A special URI that specifies the amount to be transferred and the destination account.": [
        ""
      ],
      "QR code": [
        ""
      ],
      "If your device has a camera, you can import a payto:// URI from a QR code.": [
        ""
      ],
      "Cashout": [
        ""
      ],
      "Recipient": [
        ""
      ],
      "ID of the recipient's account": [
        ""
      ],
      "username": [
        ""
      ],
      "IBAN of the recipient's account": [
        ""
      ],
      "Transfer subject": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Some text to identify the transfer": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Amount to transfer": [
        ""
      ],
      "Payto URI:": [
        ""
      ],
      "Uniform resource identifier of the target account": [
        ""
      ],
      "payto://x-taler-bank/[bank-host]/[receiver-account]?message=[subject]&amount=[%1$s:X.Y]": [
        ""
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        ""
      ],
      "The maximum amount for a wire transfer is %1$s": [
        ""
      ],
      "Cost": [
        ""
      ],
      "Cancel": [
        ""
      ],
      "Send": [
        ""
      ],
      "The target type is unknown, use \"%1$s\"": [
        ""
      ],
      "Only \"x-taler-bank\" target are supported": [
        ""
      ],
      "Only this host is allowed. Use \"%1$s\"": [
        ""
      ],
      "Account name is missing": [
        ""
      ],
      "Only \"IBAN\" target are supported": [
        ""
      ],
      "Missing \"amount\" parameter to specify the amount to be transferred": [
        ""
      ],
      "The \"amount\" parameter is not valid": [
        ""
      ],
      "\"message\" parameters to specify a reference text for the transfer are missing": [
        ""
      ],
      "The only currency allowed is \"%1$s\"": [
        ""
      ],
      "You cannot transfer an amount of zero.": [
        ""
      ],
      "The balance is not sufficient": [
        ""
      ],
      "Please enter a longer subject": [
        ""
      ],
      "Currently, the bank is not accepting new registrations!": [
        ""
      ],
      "The name is missing": [
        ""
      ],
      "Missing username": [
        ""
      ],
      "Missing password": [
        ""
      ],
      "The password should be longer than 8 letters": [
        ""
      ],
      "The passwords do not match": [
        ""
      ],
      "Server replied with invalid phone or email.": [
        ""
      ],
      "You are not authorised to create this account.": [
        ""
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        ""
      ],
      "That username can't be used because is reserved.": [
        ""
      ],
      "That username is already taken.": [
        ""
      ],
      "That account ID is already taken.": [
        ""
      ],
      "No information for the selected authentication channel.": [
        ""
      ],
      "Authentication channel is not supported.": [
        ""
      ],
      "Only an administrator is allowed to set the debt limit.": [
        ""
      ],
      "Only the administrator can change the minimum cashout limit.": [
        ""
      ],
      "Only admin can create accounts with second factor authentication.": [
        ""
      ],
      "The password is too short.": [
        ""
      ],
      "The password is too long.": [
        ""
      ],
      "Account registration": [
        ""
      ],
      "Login username": [
        ""
      ],
      "Password": [
        ""
      ],
      "Use a strong password: 8 characters minimum, don't use any public information related to you (names, birthday, phone number, etc...) and mix lowercase, uppercase, symbols and numbers": [
        ""
      ],
      "Repeat password": [
        ""
      ],
      "Full name": [
        ""
      ],
      "Register": [
        ""
      ],
      "Create a random temporary user": [
        ""
      ],
      "Wrong credentials for \"%1$s\"": [
        ""
      ],
      "Account not found": [
        ""
      ],
      "Username": [
        ""
      ],
      "Username of the account": [
        ""
      ],
      "Password of the account": [
        ""
      ],
      "Check": [
        ""
      ],
      "Log in": [
        ""
      ],
      "Transactions history": [
        ""
      ],
      "No transactions yet.": [
        ""
      ],
      "You can make a transfer or a withdrawal to your wallet.": [
        ""
      ],
      "Date": [
        ""
      ],
      "Counterpart": [
        ""
      ],
      "sent": [
        ""
      ],
      "received": [
        ""
      ],
      "Invalid value": [
        ""
      ],
      "to": [
        ""
      ],
      "from": [
        ""
      ],
      "First page": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire transfer completed!": [
        ""
      ],
      "The withdrawal has been aborted previously and can't be confirmed": [
        ""
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.": [
        ""
      ],
      "The operation ID is invalid.": [
        ""
      ],
      "The operation was not found.": [
        ""
      ],
      "The starting withdrawal amount and the confirmation amount differs.": [
        ""
      ],
      "The bank requires a bank account which has not been specified yet.": [
        ""
      ],
      "The reserve operation has been confirmed previously and can't be aborted": [
        ""
      ],
      "Confirm the withdrawal operation": [
        ""
      ],
      "Wire transfer details": [
        ""
      ],
      "Payment Service Provider's account": [
        ""
      ],
      "Payment Service Provider's account number": [
        ""
      ],
      "Payment Service Provider's name": [
        ""
      ],
      "Payment Service Provider's account bank hostname": [
        ""
      ],
      "Payment Service Provider's account id": [
        ""
      ],
      "Payment Service Provider's account address": [
        ""
      ],
      "No amount has yet been determined.": [
        ""
      ],
      "Transfer": [
        ""
      ],
      "Authentication required": [
        ""
      ],
      "This operation was created with another username": [
        ""
      ],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.": [
        ""
      ],
      "The operation was rejected due to insufficient funds.": [
        ""
      ],
      "Withdrawal confirmed": [
        ""
      ],
      "The wire transfer to the Payment Service Provider has been initiated. You will shortly receive the requested amount in your Taler wallet.": [
        ""
      ],
      "Do not show this again": [
        ""
      ],
      "Close": [
        ""
      ],
      "If you have a Taler wallet installed on this device": [
        ""
      ],
      "Your wallet will display the details of the transaction including the fees (if applicable). If you do not yet have a wallet, please follow the instructions": [
        ""
      ],
      "on this page": [
        ""
      ],
      "Withdraw": [
        ""
      ],
      "In case you have a Taler wallet on another device": [
        ""
      ],
      "Scan the QR below to start the withdrawal.": [
        ""
      ],
      "There is an operation already pending": [
        ""
      ],
      "Complete the operation in": [
        ""
      ],
      "this page": [
        ""
      ],
      "Invalid": [
        ""
      ],
      "Balance is not enough": [
        ""
      ],
      "The server replied with an invalid taler://withdraw URI": [
        ""
      ],
      "Withdraw URI: %1$s": [
        ""
      ],
      "The operation was rejected due to insufficient funds": [
        ""
      ],
      "Current balance is %1$s": [
        ""
      ],
      "You can withdraw up to %1$s": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Use your Taler wallet": [
        ""
      ],
      "After using your wallet you will need to authorize or cancel the operation on this site.": [
        ""
      ],
      "You need a Taler wallet": [
        ""
      ],
      "If you don't have one yet you can follow the instruction in": [
        ""
      ],
      "Send money": [
        ""
      ],
      "to a Taler wallet": [
        ""
      ],
      "Withdraw digital money into your mobile wallet or browser extension": [
        ""
      ],
      "to another bank account": [
        ""
      ],
      "Make a wire transfer to an account with known bank account number.": [
        ""
      ],
      "This is a demo": [
        ""
      ],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s .": [
        ""
      ],
      "Here you will be able to see how a bank that supports Taler directly would work.": [
        ""
      ],
      "Pending account delete operation": [
        ""
      ],
      "Pending account update operation": [
        ""
      ],
      "Pending password update operation": [
        ""
      ],
      "Pending transaction operation": [
        ""
      ],
      "Pending withdrawal operation": [
        ""
      ],
      "Pending cashout operation": [
        ""
      ],
      "You can complete or cancel the operation in": [
        ""
      ],
      "Internal error, please report.": [
        ""
      ],
      "Preferences": [
        ""
      ],
      "Welcome": [
        ""
      ],
      "Welcome, %1$s": [
        ""
      ],
      "History of public accounts": [
        ""
      ],
      "Scan the QR code below to start the withdrawal.": [
        ""
      ],
      "Operation aborted": [
        ""
      ],
      "The wire transfer to the Payment Service Provider's account was aborted from somewhere else, your balance was not affected.": [
        ""
      ],
      "Go to your wallet now": [
        ""
      ],
      "The operation is marked as selected, but a process during the withdrawal failed": [
        ""
      ],
      "The account was selected, but no withdrawal reserve ID was found.": [
        ""
      ],
      "There is a withdrawal reserve ID but no account has been selected or the selected account is invalid.": [
        ""
      ],
      "A withdrawal reserve ID was not found and the no account has been selected.": [
        ""
      ],
      "Operation not found": [
        ""
      ],
      "This process is not known to the server. The process ID is incorrect or the server has deleted the process information before it arrived here.": [
        ""
      ],
      "Continue to dashboard": [
        ""
      ],
      "Confirmation codes are numerical, possibly beginning with 'T-.'": [
        ""
      ],
      "No cashout was found. The cashout process has probably already been aborted.": [
        ""
      ],
      "Challenge not found.": [
        ""
      ],
      "This user is not authorized to complete this challenge.": [
        ""
      ],
      "Too many attempts, try another code.": [
        ""
      ],
      "The confirmation code is wrong, try again.": [
        ""
      ],
      "The operation expired.": [
        ""
      ],
      "The operation failed.": [
        ""
      ],
      "The operation needs another confirmation to complete.": [
        ""
      ],
      "Confirm the operation": [
        ""
      ],
      "This operation is protected with second factor authentication. In order to complete it we need to verify your identity using the authentication channel you provided.": [
        ""
      ],
      "Enter the confirmation code": [
        ""
      ],
      "You should have received a code on your mobile phone.": [
        ""
      ],
      "You should have received a code in your email.": [
        ""
      ],
      "The confirmation code starts with \"%1$s\" followed by numbers.": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "Removing account": [
        ""
      ],
      "Updating account values": [
        ""
      ],
      "Updating password": [
        ""
      ],
      "Making a wire transfer": [
        ""
      ],
      "Confirming withdrawal": [
        ""
      ],
      "Making a cashout": [
        ""
      ],
      "Operation:": [
        ""
      ],
      "Type": [
        ""
      ],
      "Updating account settings": [
        ""
      ],
      "Account": [
        ""
      ],
      "To account": [
        ""
      ],
      "Cashout account": [
        ""
      ],
      "Email": [
        ""
      ],
      "Phone": [
        ""
      ],
      "Debit threshold": [
        ""
      ],
      "Is this account public?": [
        ""
      ],
      "Enable": [
        ""
      ],
      "Disable": [
        ""
      ],
      "Name": [
        ""
      ],
      "Authentication channel": [
        ""
      ],
      "Remove": [
        ""
      ],
      "New password": [
        ""
      ],
      "Challenge details": [
        ""
      ],
      "Sent at": [
        ""
      ],
      "To phone": [
        ""
      ],
      "To email": [
        ""
      ],
      "Send again": [
        ""
      ],
      "Withdraw reserve ID": [
        ""
      ],
      "Cashout is disabled": [
        ""
      ],
      "Cashout should be enabled in the configuration, the conversion rate should be initialized with fee(s), rates and a rounding mode.": [
        ""
      ],
      "Make a wire transfer": [
        ""
      ],
      "The Withdrawal URI is not valid": [
        ""
      ],
      "Cashout should be enable by configuration and the conversion rate should be initialized with fee, ratio and rounding mode.": [
        ""
      ],
      "Latest cashouts": [
        ""
      ],
      "Created": [
        ""
      ],
      "Total debit": [
        ""
      ],
      "Total credit": [
        ""
      ],
      "Select a section": [
        ""
      ],
      "Details": [
        ""
      ],
      "Delete": [
        ""
      ],
      "Credentials": [
        ""
      ],
      "Cashouts": [
        ""
      ],
      "Conversion": [
        ""
      ],
      "Unable to create a cashout": [
        ""
      ],
      "The bank configuration does not support cashout operations.": [
        ""
      ],
      "Amount needs to be higher": [
        ""
      ],
      "It is not possible to cashout less than %1$s": [
        ""
      ],
      "Your account can't cashout less than %1$s": [
        ""
      ],
      "The total transfer to the destination will be zero": [
        ""
      ],
      "Cashout created": [
        ""
      ],
      "Duplicated request detected, check if the operation succeeded or try again.": [
        ""
      ],
      "The conversion rate was applied incorrectly": [
        ""
      ],
      "The account does not have sufficient funds": [
        ""
      ],
      "Missing cashout URI in the profile": [
        ""
      ],
      "The amount is below the minimum amount permitted.": [
        ""
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.": [
        ""
      ],
      "The server doesn't support the current TAN channel.": [
        ""
      ],
      "Conversion rate": [
        ""
      ],
      "Balance": [
        ""
      ],
      "Fee": [
        ""
      ],
      "Legal name": [
        ""
      ],
      "If this name doesn't match the account holder's name, your transaction may fail.": [
        ""
      ],
      "No cashout account": [
        ""
      ],
      "Before being able to cashout to a bank account, you need to complete your profile": [
        ""
      ],
      "Currency": [
        ""
      ],
      "Send %1$s": [
        ""
      ],
      "Receive %1$s": [
        ""
      ],
      "Total cost": [
        ""
      ],
      "Balance left": [
        ""
      ],
      "Before fee": [
        ""
      ],
      "Total cashout transfer": [
        ""
      ],
      "Cashout for account %1$s": [
        ""
      ],
      "Doesn't have the pattern of an email": [
        ""
      ],
      "Should start with +": [
        ""
      ],
      "A phone number consists of numbers only": [
        ""
      ],
      "Account ID for authentication": [
        ""
      ],
      "Name of the account holder": [
        ""
      ],
      "Internal account": [
        ""
      ],
      "If this field is empty, a random account ID will be assigned": [
        ""
      ],
      "You can copy and share this IBAN number in order to receive wire transfers to your bank account": [
        ""
      ],
      "To be used when second factor authentication is enabled": [
        ""
      ],
      "External account number where the money is going to be sent when doing cashouts": [
        ""
      ],
      "Max debt": [
        ""
      ],
      "How much the balance can go below zero.": [
        ""
      ],
      "Minimum cashout": [
        ""
      ],
      "Custom minimum cashout amount for this account.": [
        ""
      ],
      "Public accounts have their balance publicly accessible": [
        ""
      ],
      "Does this account belong to a Payment Service Provider?": [
        ""
      ],
      "Account updated": [
        ""
      ],
      "The rights to change the account are not sufficient": [
        ""
      ],
      "The username was not found": [
        ""
      ],
      "You can't change the legal name, please contact the your account administrator.": [
        ""
      ],
      "You can't change the debt limit, please contact the your account administrator.": [
        ""
      ],
      "You can't change the cashout address, please contact the your account administrator.": [
        ""
      ],
      "Account \"%1$s\"": [
        ""
      ],
      "Removed": [
        ""
      ],
      "This account can't be used.": [
        ""
      ],
      "Change details": [
        ""
      ],
      "Update": [
        ""
      ],
      "Merchant integration": [
        ""
      ],
      "Use this information to link your Taler Merchant Backoffice account with the current bank account. You can start by copying the values, then go to your merchant backoffice service provider, login into your account and look for the \"import\" button in the \"bank account\" section.": [
        ""
      ],
      "Account type": [
        ""
      ],
      "Method to use for wire transfer.": [
        ""
      ],
      "IBAN": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Account name": [
        ""
      ],
      "Bank host where the service is located.": [
        ""
      ],
      "Bank account identifier for wire transfers.": [
        ""
      ],
      "Address": [
        ""
      ],
      "Owner's name": [
        ""
      ],
      "Legal name of the person holding the account.": [
        ""
      ],
      "Account info URL": [
        ""
      ],
      "From where the merchant can download information about incoming wire transfers to this account.": [
        ""
      ],
      "Copy": [
        ""
      ],
      "Repeated password doesn't match": [
        ""
      ],
      "Password changed": [
        ""
      ],
      "Not authorized to change the password, maybe the session is invalid.": [
        ""
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.": [
        ""
      ],
      "Your current password doesn't match, can't change to a new password.": [
        ""
      ],
      "Update password": [
        ""
      ],
      "Current password": [
        ""
      ],
      "Your current password, for security": [
        ""
      ],
      "Type it again": [
        ""
      ],
      "Repeat the same password": [
        ""
      ],
      "Change": [
        ""
      ],
      "Accounts": [
        ""
      ],
      "Create account": [
        ""
      ],
      "Actions": [
        ""
      ],
      "Unknown": [
        ""
      ],
      "Change password": [
        ""
      ],
      "Querying for the current stats failed": [
        ""
      ],
      "The request parameters are wrong": [
        ""
      ],
      "The user is unauthorized": [
        ""
      ],
      "Querying for the previous stats failed": [
        ""
      ],
      "Transaction volume report": [
        ""
      ],
      "Last hour": [
        ""
      ],
      "Previous day": [
        ""
      ],
      "Last month": [
        ""
      ],
      "Last year": [
        ""
      ],
      "Last Year": [
        ""
      ],
      "Trading volume from %1$s to %2$s": [
        ""
      ],
      "Cashin": [
        ""
      ],
      "Transferred from an external account to an account in this bank.": [
        ""
      ],
      "Transferred from an account in this bank to an external account.": [
        ""
      ],
      "Payin": [
        ""
      ],
      "Transferred from an account to a Taler exchange.": [
        ""
      ],
      "Payout": [
        ""
      ],
      "Transferred from a Taler exchange to another account.": [
        ""
      ],
      "Download stats as CSV": [
        ""
      ],
      "previous": [
        ""
      ],
      "Decreased by": [
        ""
      ],
      "Increased by": [
        ""
      ],
      "Account created with password \"%1$s\".": [
        ""
      ],
      "Server replied that phone or email is invalid": [
        ""
      ],
      "The rights to perform the operation are not sufficient": [
        ""
      ],
      "Account username is already taken": [
        ""
      ],
      "Account id is already taken": [
        ""
      ],
      "Bank ran out of bonus credit.": [
        ""
      ],
      "Account username can't be used because is reserved": [
        ""
      ],
      "Can't create accounts": [
        ""
      ],
      "Only system admin can create accounts.": [
        ""
      ],
      "New bank account": [
        ""
      ],
      "Create": [
        ""
      ],
      "Download bank stats": [
        ""
      ],
      "Include hour metric": [
        ""
      ],
      "Include day metric": [
        ""
      ],
      "Include month metric": [
        ""
      ],
      "Include year metric": [
        ""
      ],
      "Include table header": [
        ""
      ],
      "Add previous metric for compare": [
        ""
      ],
      "Fail on first error": [
        ""
      ],
      "Download": [
        ""
      ],
      "downloading... %1$s": [
        ""
      ],
      "Download completed": [
        ""
      ],
      "Click here to save the file in your computer.": [
        ""
      ],
      "Can't delete the account": [
        ""
      ],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.": [
        ""
      ],
      "Account removed": [
        ""
      ],
      "No enough permission to delete the account.": [
        ""
      ],
      "The username was not found.": [
        ""
      ],
      "Can't delete a reserved username.": [
        ""
      ],
      "Can't delete an account with balance different than zero.": [
        ""
      ],
      "Name doesn't match": [
        ""
      ],
      "You are going to remove the account": [
        ""
      ],
      "This step can't be undone.": [
        ""
      ],
      "Deleting account \"%1$s\"": [
        ""
      ],
      "Verification": [
        ""
      ],
      "Enter the account name that is going to be deleted": [
        ""
      ],
      "loading...": [
        ""
      ],
      "only admin can setup conversion": [
        ""
      ],
      "Wrong credentials": [
        ""
      ],
      "Conversion is disabled": [
        ""
      ],
      "Config cashout": [
        ""
      ],
      "Config cashin": [
        ""
      ],
      "Cashin ratio": [
        ""
      ],
      "Cashout ratio": [
        ""
      ],
      "Bad ratios": [
        ""
      ],
      "One of the ratios should be higher or equal than 1 an the other should be lower or equal than 1.": [
        ""
      ],
      "Initial amount": [
        ""
      ],
      "Use it to test how the conversion will affect the amount.": [
        ""
      ],
      "Sending to this bank": [
        ""
      ],
      "Converted": [
        ""
      ],
      "Cashin after fee": [
        ""
      ],
      "Sending from this bank": [
        ""
      ],
      "Cashout after fee": [
        ""
      ],
      "Bad configuration": [
        ""
      ],
      "This configuration allows users to cash out more of what has been cashed in.": [
        ""
      ],
      "Rnvalid": [
        ""
      ],
      "Minimum amount": [
        ""
      ],
      "Only cashout operation above this threshold will be allowed": [
        ""
      ],
      "Ratio": [
        ""
      ],
      "Conversion ratio between currencies": [
        ""
      ],
      "Example conversion": [
        ""
      ],
      "1 %1$s will be converted into %2$s %3$s": [
        ""
      ],
      "Rounding value": [
        ""
      ],
      "Smallest difference between two amounts after the ratio is applied.": [
        ""
      ],
      "Rounding mode": [
        ""
      ],
      "Zero": [
        ""
      ],
      "Amount will be round below to the largest possible value smaller than the input.": [
        ""
      ],
      "Up": [
        ""
      ],
      "Amount will be round up to the smallest possible value larger than the input.": [
        ""
      ],
      "Nearest": [
        ""
      ],
      "Amount will be round to the closest possible value.": [
        ""
      ],
      "Examples": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.1": [
        ""
      ],
      "Given the rounding value of 0.1 the possible values closest to 1.24 are: 1.1, 1.2, 1.3, 1.4.": [
        ""
      ],
      "With the \"zero\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.1": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.3": [
        ""
      ],
      "Given the rounding value of 0.3 the possible values closest to 1.24 are: 0.9, 1.2, 1.5, 1.8.": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.5": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.3": [
        ""
      ],
      "Amount to be deducted before amount is credited.": [
        ""
      ],
      "Cashout id should be a number": [
        ""
      ],
      "This cashout not found. Maybe already aborted.": [
        ""
      ],
      "Cashout detail": [
        ""
      ],
      "Debited": [
        ""
      ],
      "Credited": [
        ""
      ],
      "Welcome to %1$s!": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n > 1;",
  "lang": "fr",
  "completeness": 0
};

strings['es'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "es"
      },
      "Show withdrawal confirmation": [
        "Mostrar confirmación de extracción"
      ],
      "Withdraw without setting amount": [
        ""
      ],
      "Show copy account letter": [
        ""
      ],
      "Show demo description": [
        "Mostrar descripción de demo"
      ],
      "Show install wallet first": [
        "Mostrar instalar la billetera primero"
      ],
      "Remove password length validation on registration": [
        ""
      ],
      "Show debug info": [
        "Mostrar información de depuración"
      ],
      "Operation failed, please report": [
        "La operaicón falló, por favor reportelo"
      ],
      "Request timeout": [
        "La petición al servidor agoto su tiempo"
      ],
      "Request throttled": [
        "La petición al servidor interrumpida"
      ],
      "Malformed response": [
        "Respuesta malformada"
      ],
      "Network error": [
        "Error de conexión"
      ],
      "Unexpected request error": [
        "Error de pedido inesperado"
      ],
      "Unexpected error": [
        "Error inesperado"
      ],
      "An IBAN consists of capital letters and numbers only": [
        "IBAN debería tener letras mayúsculas y números"
      ],
      "IBAN numbers have more that 4 digits": [
        "Los números IBAN usualmente tienen mas de 4 digitos"
      ],
      "IBAN numbers have less that 34 digits": [
        "Los números IBAN usualmente tienen menos de 34 digitos"
      ],
      "IBAN country code not found": [
        "Código de pais de IBAN no encontrado"
      ],
      "IBAN number is not valid, checksum is wrong": [
        "El número IBAN no es válido, falló la verificación"
      ],
      "Use letters, numbers or any of these characters: - . _ ~": [
        ""
      ],
      "Required": [
        "requerido"
      ],
      "Not valid": [
        "no válido"
      ],
      "Does not follow the pattern": [
        "no tiene un patrón valido"
      ],
      "The request was invalid or the payto://-URI used unacceptable features.": [
        "El pedido era inválido o el URI payto:// usado tiene características inaceptables."
      ],
      "Not enough permission to complete the operation.": [
        "Sin permisos suficientes para completar la operación."
      ],
      "The bank administrator cannot be the transfer creditor.": [
        ""
      ],
      "The destination account \"%1$s\" was not found.": [
        "La cuenta de destino \"%1$s\" no fue encontrada."
      ],
      "The origin and the destination of the transfer can't be the same.": [
        "El origen y destino de la transferencia no puede ser la misma."
      ],
      "Your balance is not sufficient for the operation.": [
        "El saldo no es suficiente para la operación."
      ],
      "The origin account \"%1$s\" was not found.": [
        "La cuenta origen \"%1$s\" no fue encontrada."
      ],
      "The attempt to create the transaction has failed. Please try again.": [
        ""
      ],
      "The wire transfer was successfully completed!": [
        "Transferencia bancaria completada!"
      ],
      "Input wire transfer detail": [
        "Detalle de transferencia bancaria"
      ],
      "Using a form": [
        "Usando un formulario"
      ],
      "A special URI that specifies the amount to be transferred and the destination account.": [
        ""
      ],
      "QR code": [
        "Enviar código"
      ],
      "If your device has a camera, you can import a payto:// URI from a QR code.": [
        ""
      ],
      "Cashout": [
        "Egreso"
      ],
      "Recipient": [
        "Destinatario"
      ],
      "ID of the recipient's account": [
        "Numero IBAN de la cuenta destinataria"
      ],
      "username": [
        "Nombre de usuario"
      ],
      "IBAN of the recipient's account": [
        "Numero IBAN de la cuenta destinataria"
      ],
      "Transfer subject": [
        "Asunto de transferencia"
      ],
      "Subject": [
        "Asunto"
      ],
      "Some text to identify the transfer": [
        "algún texto para identificar la transferencia"
      ],
      "Amount": [
        "Monto"
      ],
      "Amount to transfer": [
        "monto a transferir"
      ],
      "Payto URI:": [
        "payto URI:"
      ],
      "Uniform resource identifier of the target account": [
        "identificador de recurso uniforme de la cuenta destino"
      ],
      "payto://x-taler-bank/[bank-host]/[receiver-account]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban-destinatario]?message=[asunto]&amount=[%1$s:X.Y]"
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[iban-destinatario]?message=[asunto]&amount=[%1$s:X.Y]"
      ],
      "The maximum amount for a wire transfer is %1$s": [
        ""
      ],
      "Cost": [
        ""
      ],
      "Cancel": [
        "Cancelar"
      ],
      "Send": [
        "Envíar"
      ],
      "The target type is unknown, use \"%1$s\"": [
        ""
      ],
      "Only \"x-taler-bank\" target are supported": [
        "solo cuentas \"IBAN\" son soportadas"
      ],
      "Only this host is allowed. Use \"%1$s\"": [
        ""
      ],
      "Account name is missing": [
        "Actualización de cuenta"
      ],
      "Only \"IBAN\" target are supported": [
        "solo cuentas \"IBAN\" son soportadas"
      ],
      "Missing \"amount\" parameter to specify the amount to be transferred": [
        "usa el parámetro \"amount\" para indicar el monto a ser transferido"
      ],
      "The \"amount\" parameter is not valid": [
        "el monto no es válido"
      ],
      "\"message\" parameters to specify a reference text for the transfer are missing": [
        "usa el parámetro \"message\" para indicar un texto de referencia en la transferencia"
      ],
      "The only currency allowed is \"%1$s\"": [
        ""
      ],
      "You cannot transfer an amount of zero.": [
        "No se puede transferir una cantidad de cero."
      ],
      "The balance is not sufficient": [
        "La cuenta no tiene fondos suficientes"
      ],
      "Please enter a longer subject": [
        "Asunto de transferencia"
      ],
      "Currently, the bank is not accepting new registrations!": [
        "Actualmente, el banco no está aceptado nuevos registros!"
      ],
      "The name is missing": [
        ""
      ],
      "Missing username": [
        "Falta nombre de usuario"
      ],
      "Missing password": [
        "Falta contraseña"
      ],
      "The password should be longer than 8 letters": [
        "Debería ser mas grande que 0"
      ],
      "The passwords do not match": [
        "La contraseña no coincide"
      ],
      "Server replied with invalid phone or email.": [
        "El servidor repondio con teléfono o dirección de correo inválido."
      ],
      "You are not authorised to create this account.": [
        "Sin permisos suficientes para crear esa cuenta."
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        "El registro está deshabilitado porque el banco se quedó sin crédito bonus."
      ],
      "That username can't be used because is reserved.": [
        "El nombre de usuario no puede ser usado porque esta reservado."
      ],
      "That username is already taken.": [
        "El nombre de usuario ya está tomado."
      ],
      "That account ID is already taken.": [
        "El identificador de cuenta ya está tomado."
      ],
      "No information for the selected authentication channel.": [
        "No hay información para el canal de autenticación seleccionado."
      ],
      "Authentication channel is not supported.": [
        "Canal de autenticación no esta soportado."
      ],
      "Only an administrator is allowed to set the debt limit.": [
        "Solo el administrador tiene permitido cambiar el límite de deuda."
      ],
      "Only the administrator can change the minimum cashout limit.": [
        ""
      ],
      "Only admin can create accounts with second factor authentication.": [
        "Solo el administrador puede crear cuentas con el segundo factor de autenticación."
      ],
      "The password is too short.": [
        "La contraseña es demasiado corta."
      ],
      "The password is too long.": [
        "La contraseña es demasiado larga."
      ],
      "Account registration": [
        "Registro de cuenta"
      ],
      "Login username": [
        "Falta nombre de usuario"
      ],
      "Password": [
        "Contrasena"
      ],
      "Use a strong password: 8 characters minimum, don't use any public information related to you (names, birthday, phone number, etc...) and mix lowercase, uppercase, symbols and numbers": [
        ""
      ],
      "Repeat password": [
        "Repita la contraseña"
      ],
      "Full name": [
        ""
      ],
      "Register": [
        "Registrarse"
      ],
      "Create a random temporary user": [
        "Crear un usuario aleatorio temporal"
      ],
      "Wrong credentials for \"%1$s\"": [
        "Credenciales incorrectas para \"%1$s\""
      ],
      "Account not found": [
        "Cuenta no encontrada"
      ],
      "Username": [
        "Usuario"
      ],
      "Username of the account": [
        "nombre de usuario de la cuenta"
      ],
      "Password of the account": [
        "contraseña de la cuenta"
      ],
      "Check": [
        "Verificar"
      ],
      "Log in": [
        "Acceso"
      ],
      "Transactions history": [
        ""
      ],
      "No transactions yet.": [
        "Aún no hay transacciones."
      ],
      "You can make a transfer or a withdrawal to your wallet.": [
        ""
      ],
      "Date": [
        "Fecha"
      ],
      "Counterpart": [
        "Contra cuenta"
      ],
      "sent": [
        "enviado"
      ],
      "received": [
        "recibido"
      ],
      "Invalid value": [
        "valor inválido"
      ],
      "to": [
        "hacia"
      ],
      "from": [
        "desde"
      ],
      "First page": [
        "Primera página"
      ],
      "Next": [
        "Siguiente"
      ],
      "Wire transfer completed!": [
        "Transferencia bancaria completada!"
      ],
      "The withdrawal has been aborted previously and can't be confirmed": [
        "La extracción fue abortada anteriormente y no puede ser confirmada"
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.": [
        "La operación de extracción no puede ser confirmada antes de que una billetera acepte la transaccion."
      ],
      "The operation ID is invalid.": [
        "El id de operación es invalido."
      ],
      "The operation was not found.": [
        "La operación no se encontró."
      ],
      "The starting withdrawal amount and the confirmation amount differs.": [
        ""
      ],
      "The bank requires a bank account which has not been specified yet.": [
        ""
      ],
      "The reserve operation has been confirmed previously and can't be aborted": [
        "La operación en la reserva ya ha sido confirmada previamente y no puede ser abortada"
      ],
      "Confirm the withdrawal operation": [
        "Confirme la operación de extracción"
      ],
      "Wire transfer details": [
        "Detalle de transferencia bancaria"
      ],
      "Payment Service Provider's account": [
        "Cuenta del operador del Taler Exchange"
      ],
      "Payment Service Provider's account number": [
        "Cuenta del operador del Taler Exchange"
      ],
      "Payment Service Provider's name": [
        "Cuenta del operador del Taler Exchange"
      ],
      "Payment Service Provider's account bank hostname": [
        "Cuenta del operador del Taler Exchange"
      ],
      "Payment Service Provider's account id": [
        "Cuenta del operador del Taler Exchange"
      ],
      "Payment Service Provider's account address": [
        "Cuenta del operador del Taler Exchange"
      ],
      "No amount has yet been determined.": [
        ""
      ],
      "Transfer": [
        "Transferencia"
      ],
      "Authentication required": [
        "Autenticación requerida"
      ],
      "This operation was created with another username": [
        "Esta operación fue creada con otro usuario"
      ],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.": [
        "No autorizado para hacer la operación, quizá la sesión haya expirado or cambió la contraseña."
      ],
      "The operation was rejected due to insufficient funds.": [
        "La operación fue rechazada debido a saldo insuficiente."
      ],
      "Withdrawal confirmed": [
        "La extracción fue confirmada"
      ],
      "The wire transfer to the Payment Service Provider has been initiated. You will shortly receive the requested amount in your Taler wallet.": [
        "La transferencia bancaria al operador Taler fue iniciada. Pronto recibirás el monto pedido en tu billetera Taler."
      ],
      "Do not show this again": [
        "No mostrar otra vez"
      ],
      "Close": [
        "Cerrar"
      ],
      "If you have a Taler wallet installed on this device": [
        "Si tienes una billetera Taler instalada en este dispositivo"
      ],
      "Your wallet will display the details of the transaction including the fees (if applicable). If you do not yet have a wallet, please follow the instructions": [
        "Veras los detalles de la operación en tu billetera incluyendo comisiones (si aplicán). Si todavía no tienes una puedes instalarla siguiendo las instrucciones en"
      ],
      "on this page": [
        "esta página"
      ],
      "Withdraw": [
        "Retirar"
      ],
      "In case you have a Taler wallet on another device": [
        "O si tienes la billetera en otro dispositivo"
      ],
      "Scan the QR below to start the withdrawal.": [
        "Escanea el QR debajo para comenzar la extracción."
      ],
      "There is an operation already pending": [
        "Ya hay una operación"
      ],
      "Complete the operation in": [
        "Completa o cancela la operación en"
      ],
      "this page": [
        "esta página"
      ],
      "Invalid": [
        "inválido"
      ],
      "Balance is not enough": [
        "el saldo no es suficiente"
      ],
      "The server replied with an invalid taler://withdraw URI": [
        "El servidor respondió con una URI de extracción inválida"
      ],
      "Withdraw URI: %1$s": [
        "URI de extracción: %1$s"
      ],
      "The operation was rejected due to insufficient funds": [
        "La operación fue rechazada debido a fundos insuficientes"
      ],
      "Current balance is %1$s": [
        ""
      ],
      "You can withdraw up to %1$s": [
        ""
      ],
      "Continue": [
        "Continuar"
      ],
      "Use your Taler wallet": [
        "Utiliza tu cartera Taler"
      ],
      "After using your wallet you will need to authorize or cancel the operation on this site.": [
        "Despues de usar tu billetera necesitarás confirmar o cancelar la operación en este sitio."
      ],
      "You need a Taler wallet": [
        "Necesitas una GNU Taler Wallet"
      ],
      "If you don't have one yet you can follow the instruction in": [
        "Si no tienes una todavía puedes seguir las instrucciones en"
      ],
      "Send money": [
        "Enviar dinero"
      ],
      "to a Taler wallet": [
        "a una cartera Taler"
      ],
      "Withdraw digital money into your mobile wallet or browser extension": [
        "Extraer dinero digital a tu billetera móvil o extensión web"
      ],
      "to another bank account": [
        "a otra cuenta bancaria"
      ],
      "Make a wire transfer to an account with known bank account number.": [
        "Hacer una transferencia bancaria a una cuenta con un número de cuenta conocido."
      ],
      "This is a demo": [
        "Este es un banco de demostración"
      ],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s .": [
        "Esta parte de la demostración muestra cómo funciona un banco que soporta Taler directamente. Además de usar tu propia cuenta de banco, también podrás ver el historial de transacciones de algunas %1$s."
      ],
      "Here you will be able to see how a bank that supports Taler directly would work.": [
        "Esta parte de la demostración muetra como un banco que soporta Taler directamente funcionaría."
      ],
      "Pending account delete operation": [
        "Operación pendiente de eliminación de cuenta"
      ],
      "Pending account update operation": [
        "Operación pendiente de actualización de cuenta"
      ],
      "Pending password update operation": [
        "Operación pendiente de actualización de password"
      ],
      "Pending transaction operation": [
        "Operación pendiente de transacción"
      ],
      "Pending withdrawal operation": [
        "Operación pendiente de extracción"
      ],
      "Pending cashout operation": [
        "Operación pendiente de egreso"
      ],
      "You can complete or cancel the operation in": [
        "Puedes completar o cancelar la operación en"
      ],
      "Internal error, please report.": [
        "Error interno, por favor reporte el error."
      ],
      "Preferences": [
        "Preferencias"
      ],
      "Welcome": [
        "Bienvenido/a"
      ],
      "Welcome, %1$s": [
        "Bienvenido/a, %1$s"
      ],
      "History of public accounts": [
        "Historial de cuentas públicas"
      ],
      "Scan the QR code below to start the withdrawal.": [
        "Escanea el QR debajo para comenzar la extracción."
      ],
      "Operation aborted": [
        "Operación abortada"
      ],
      "The wire transfer to the Payment Service Provider's account was aborted from somewhere else, your balance was not affected.": [
        "La transferencia bancaria a la cuenta del operador del Taler Exchange fue abortada, su saldo no fue afectado."
      ],
      "Go to your wallet now": [
        "Accede a tu cartera ahora"
      ],
      "The operation is marked as selected, but a process during the withdrawal failed": [
        "La operación está marcada como 'seleccionada' pero algunos pasos en la extracción fallaron"
      ],
      "The account was selected, but no withdrawal reserve ID was found.": [
        "La cuenta está seleccionada pero no se encontró el identificador de extracción."
      ],
      "There is a withdrawal reserve ID but no account has been selected or the selected account is invalid.": [
        "Hay un identificador de extracción pero la cuenta no ha sido seleccionada o la selccionada es inválida."
      ],
      "A withdrawal reserve ID was not found and the no account has been selected.": [
        "No hay un identificador de extracción y ninguna cuenta a sido seleccionada o la seleccionada es inválida."
      ],
      "Operation not found": [
        "Operación no encontrada"
      ],
      "This process is not known to the server. The process ID is incorrect or the server has deleted the process information before it arrived here.": [
        "Esta operación no es conocida por el servidor. El identificador de operación es incorrecto o el server borró la información de la operación antes de llegar hasta aquí."
      ],
      "Continue to dashboard": [
        "Continuar al panel"
      ],
      "Confirmation codes are numerical, possibly beginning with 'T-.'": [
        ""
      ],
      "No cashout was found. The cashout process has probably already been aborted.": [
        "Egreso no econtrado. También puede significar que ya ha sido abortado."
      ],
      "Challenge not found.": [
        "Desafío no encontrado."
      ],
      "This user is not authorized to complete this challenge.": [
        "Este usuario no está autorizado para completar este desafío."
      ],
      "Too many attempts, try another code.": [
        "Demasiados intentos, intente otro código."
      ],
      "The confirmation code is wrong, try again.": [
        "El código de confirmación es erroneo, intente otra vez."
      ],
      "The operation expired.": [
        "La operación expiró."
      ],
      "The operation failed.": [
        "La operación falló."
      ],
      "The operation needs another confirmation to complete.": [
        "La operación necesita otra confirmación para completar."
      ],
      "Confirm the operation": [
        "Confirmar la operación"
      ],
      "This operation is protected with second factor authentication. In order to complete it we need to verify your identity using the authentication channel you provided.": [
        ""
      ],
      "Enter the confirmation code": [
        "Ingresar el código de confirmación"
      ],
      "You should have received a code on your mobile phone.": [
        ""
      ],
      "You should have received a code in your email.": [
        ""
      ],
      "The confirmation code starts with \"%1$s\" followed by numbers.": [
        "El código de confirmación comienza con \"%1$s\" seguido de números."
      ],
      "Confirm": [
        "Confirmar"
      ],
      "Removing account": [
        "Hacia cuenta"
      ],
      "Updating account values": [
        "Actualización de la cuenta"
      ],
      "Updating password": [
        "Actualizar contraseña"
      ],
      "Making a wire transfer": [
        "Hacer una transferencia bancaria"
      ],
      "Confirming withdrawal": [
        "Confirme la operación de extracción"
      ],
      "Making a cashout": [
        ""
      ],
      "Operation:": [
        "Operación:"
      ],
      "Type": [
        ""
      ],
      "Updating account settings": [
        "Operación pendiente de eliminación de cuenta"
      ],
      "Account": [
        "Cuentas"
      ],
      "To account": [
        "Hacia cuenta"
      ],
      "Cashout account": [
        "No hay cuenta de egreso"
      ],
      "Email": [
        "Correo eletrónico"
      ],
      "Phone": [
        "Teléfono"
      ],
      "Debit threshold": [
        "Debitado"
      ],
      "Is this account public?": [
        "Es una cuenta pública?"
      ],
      "Enable": [
        ""
      ],
      "Disable": [
        ""
      ],
      "Name": [
        "Nombre"
      ],
      "Authentication channel": [
        "Autenticación requerida"
      ],
      "Remove": [
        "elimiar"
      ],
      "New password": [
        "Nueva contraseña"
      ],
      "Challenge details": [
        "Detalles del desafío"
      ],
      "Sent at": [
        "Enviado a"
      ],
      "To phone": [
        "Al teléfono"
      ],
      "To email": [
        "Al email"
      ],
      "Send again": [
        "Enviar otra vez"
      ],
      "Withdraw reserve ID": [
        "Retirar"
      ],
      "Cashout is disabled": [
        "Egreso creado"
      ],
      "Cashout should be enabled in the configuration, the conversion rate should be initialized with fee(s), rates and a rounding mode.": [
        ""
      ],
      "Make a wire transfer": [
        "Hacer una transferencia bancaria"
      ],
      "The Withdrawal URI is not valid": [
        "El URI de estracción no es válido"
      ],
      "Cashout should be enable by configuration and the conversion rate should be initialized with fee, ratio and rounding mode.": [
        ""
      ],
      "Latest cashouts": [
        "Últimos egresos"
      ],
      "Created": [
        "Creado"
      ],
      "Total debit": [
        "Débito total"
      ],
      "Total credit": [
        "Crédito total"
      ],
      "Select a section": [
        "Seleccione una sección"
      ],
      "Details": [
        "Detalles"
      ],
      "Delete": [
        "Borrar"
      ],
      "Credentials": [
        "Credenciales"
      ],
      "Cashouts": [
        "Egresos"
      ],
      "Conversion": [
        "Tasa de conversión"
      ],
      "Unable to create a cashout": [
        "Imposible crear un egreso"
      ],
      "The bank configuration does not support cashout operations.": [
        "La configuración del banco no soporta operaciones de egreso."
      ],
      "Amount needs to be higher": [
        "necesita ser mayor debido a las comisiones"
      ],
      "It is not possible to cashout less than %1$s": [
        ""
      ],
      "Your account can't cashout less than %1$s": [
        ""
      ],
      "The total transfer to the destination will be zero": [
        "el total de la transferencia en destino será cero"
      ],
      "Cashout created": [
        "Egreso creado"
      ],
      "Duplicated request detected, check if the operation succeeded or try again.": [
        "Se detectó una petición duplicada, verifique si la operación tuvo éxito o intente otra vez."
      ],
      "The conversion rate was applied incorrectly": [
        "La tasa de conversión se aplicó incorrectamente"
      ],
      "The account does not have sufficient funds": [
        "La cuenta no tiene fondos suficientes"
      ],
      "Missing cashout URI in the profile": [
        "Falta dirección de egreso en el perfíl"
      ],
      "The amount is below the minimum amount permitted.": [
        ""
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.": [
        "El envío del mensaje de confirmación falló, intente mas tarde o contacte al administrador."
      ],
      "The server doesn't support the current TAN channel.": [
        "Este servidor no tiene soporte para segundo factor de autenticación."
      ],
      "Conversion rate": [
        "Tasa de conversión"
      ],
      "Balance": [
        "Saldo"
      ],
      "Fee": [
        "Comisión"
      ],
      "Legal name": [
        ""
      ],
      "If this name doesn't match the account holder's name, your transaction may fail.": [
        ""
      ],
      "No cashout account": [
        "No hay cuenta de egreso"
      ],
      "Before being able to cashout to a bank account, you need to complete your profile": [
        "Antes de hacer un egreso necesita completar su perfíl"
      ],
      "Currency": [
        ""
      ],
      "Send %1$s": [
        "Envíar %1$s"
      ],
      "Receive %1$s": [
        "Recibir %1$s"
      ],
      "Total cost": [
        "Costo total"
      ],
      "Balance left": [
        "Saldo remanente"
      ],
      "Before fee": [
        "Antes de comisión"
      ],
      "Total cashout transfer": [
        "Total de egreso"
      ],
      "Cashout for account %1$s": [
        "Egreso para cuenta %1$s"
      ],
      "Doesn't have the pattern of an email": [
        "no tiene el patrón de un correo electrónico"
      ],
      "Should start with +": [
        "debería comenzar con un +"
      ],
      "A phone number consists of numbers only": [
        "número de teléfono no puede tener otra cosa que numeros"
      ],
      "Account ID for authentication": [
        "Segundo factor de autenticación"
      ],
      "Name of the account holder": [
        "nombre de usuario de la cuenta"
      ],
      "Internal account": [
        "a otra cuenta bancaria"
      ],
      "If this field is empty, a random account ID will be assigned": [
        "si está vacío un número de cuenta aleatorio será asignado"
      ],
      "You can copy and share this IBAN number in order to receive wire transfers to your bank account": [
        ""
      ],
      "To be used when second factor authentication is enabled": [
        "Hábilitar segundo factor de autenticación"
      ],
      "External account number where the money is going to be sent when doing cashouts": [
        "numero de cuenta donde el dinero será enviado cuando se ejecuten egresos"
      ],
      "Max debt": [
        "Máxima deuda"
      ],
      "How much the balance can go below zero.": [
        ""
      ],
      "Minimum cashout": [
        "egresos"
      ],
      "Custom minimum cashout amount for this account.": [
        ""
      ],
      "Public accounts have their balance publicly accessible": [
        "las cuentas públicas tienen su saldo accesible al público"
      ],
      "Does this account belong to a Payment Service Provider?": [
        "Es una cuenta pública?"
      ],
      "Account updated": [
        "Cuenta actualizada"
      ],
      "The rights to change the account are not sufficient": [
        "Los permisos para cambiar la cuenta no son suficientes"
      ],
      "The username was not found": [
        "El nombre de usaurio no se encontró"
      ],
      "You can't change the legal name, please contact the your account administrator.": [
        "No puede cambiar el nombre legal, por favor contacte el administrador de la cuenta."
      ],
      "You can't change the debt limit, please contact the your account administrator.": [
        "No puede cambiar el límite de deuda, por favor contacte el administrador de la cuenta."
      ],
      "You can't change the cashout address, please contact the your account administrator.": [
        "No puede cambiar la dirección de egreso, por favor contacte al administrador de la cuenta."
      ],
      "Account \"%1$s\"": [
        "Cuenta \"%1$s\""
      ],
      "Removed": [
        "elimiar"
      ],
      "This account can't be used.": [
        "Este paso no puede ser deshecho."
      ],
      "Change details": [
        "Cambiar detalles"
      ],
      "Update": [
        "Actualizar"
      ],
      "Merchant integration": [
        "Registro de cuenta"
      ],
      "Use this information to link your Taler Merchant Backoffice account with the current bank account. You can start by copying the values, then go to your merchant backoffice service provider, login into your account and look for the \"import\" button in the \"bank account\" section.": [
        ""
      ],
      "Account type": [
        "Eliminación de cuenta"
      ],
      "Method to use for wire transfer.": [
        "Hacer una transferencia bancaria"
      ],
      "IBAN": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Account name": [
        "Actualización de cuenta"
      ],
      "Bank host where the service is located.": [
        ""
      ],
      "Bank account identifier for wire transfers.": [
        "identificador de cuenta para transferencia bancaria"
      ],
      "Address": [
        "direccion payto"
      ],
      "Owner's name": [
        "Nombre de usuario"
      ],
      "Legal name of the person holding the account.": [
        "nombre de la persona dueña de la cuenta"
      ],
      "Account info URL": [
        "Cuenta no encontrada"
      ],
      "From where the merchant can download information about incoming wire transfers to this account.": [
        ""
      ],
      "Copy": [
        ""
      ],
      "Repeated password doesn't match": [
        "la contraseña no coincide"
      ],
      "Password changed": [
        "La contraseña cambió"
      ],
      "Not authorized to change the password, maybe the session is invalid.": [
        "No está autorizado a cambiar el password, quizá la sesión es invalida."
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.": [
        "Se necesita el password viejo para cambiar la contraseña. Si no lo tiene contacte a su administrador."
      ],
      "Your current password doesn't match, can't change to a new password.": [
        "Su actual contraseña no coincide, no puede cambiar a una nueva contraseña."
      ],
      "Update password": [
        "Actualizar contraseña"
      ],
      "Current password": [
        "Contraseña actual"
      ],
      "Your current password, for security": [
        "su actual contraseña, por seguridad"
      ],
      "Type it again": [
        "Escribalo otra vez"
      ],
      "Repeat the same password": [
        "repita la misma contraseña"
      ],
      "Change": [
        "Cambiar"
      ],
      "Accounts": [
        "Cuentas"
      ],
      "Create account": [
        "Crear cuenta"
      ],
      "Actions": [
        "Acciones"
      ],
      "Unknown": [
        "desconocido"
      ],
      "Change password": [
        "cambiar contraseña"
      ],
      "Querying for the current stats failed": [
        ""
      ],
      "The request parameters are wrong": [
        ""
      ],
      "The user is unauthorized": [
        "El nombre de usaurio no se encontró"
      ],
      "Querying for the previous stats failed": [
        ""
      ],
      "Transaction volume report": [
        "La creación de la transferencia dió una respuesta erronea"
      ],
      "Last hour": [
        "Última hora"
      ],
      "Previous day": [
        ""
      ],
      "Last month": [
        "Último mes"
      ],
      "Last year": [
        "Último año"
      ],
      "Last Year": [
        "Último Año"
      ],
      "Trading volume from %1$s to %2$s": [
        "Vólumen de comercio en %1$s comparado con %2$s"
      ],
      "Cashin": [
        "Ingreso"
      ],
      "Transferred from an external account to an account in this bank.": [
        ""
      ],
      "Transferred from an account in this bank to an external account.": [
        "Hacer una transferencia bancaria a una cuenta con un número de cuenta conocido."
      ],
      "Payin": [
        "Envios de dinero"
      ],
      "Transferred from an account to a Taler exchange.": [
        ""
      ],
      "Payout": [
        "Recibos de dinero"
      ],
      "Transferred from a Taler exchange to another account.": [
        "Cuenta del operador del Taler Exchange"
      ],
      "Download stats as CSV": [
        "descargar estadísticas en CSV"
      ],
      "previous": [
        ""
      ],
      "Decreased by": [
        "Descendiente por"
      ],
      "Increased by": [
        "Ascendente por"
      ],
      "Account created with password \"%1$s\".": [
        ""
      ],
      "Server replied that phone or email is invalid": [
        "El servidor respondió que el teléfono o correo eletrónico es invalido"
      ],
      "The rights to perform the operation are not sufficient": [
        "Los permisos para ejecutar la operación no son suficientes"
      ],
      "Account username is already taken": [
        "El nombre del usuario ya está tomado"
      ],
      "Account id is already taken": [
        "El id de cuenta ya está tomado"
      ],
      "Bank ran out of bonus credit.": [
        "El banco no tiene mas crédito de bonus."
      ],
      "Account username can't be used because is reserved": [
        "El nombre de usuario de la cuenta no puede userse porque está reservado"
      ],
      "Can't create accounts": [
        "No puede crear cuentas"
      ],
      "Only system admin can create accounts.": [
        "Solo los administradores del sistema pueden crear cuentas."
      ],
      "New bank account": [
        "Nueva cuenta"
      ],
      "Create": [
        "Crear"
      ],
      "Download bank stats": [
        "Descargar estadísticas del banco"
      ],
      "Include hour metric": [
        "Incluir métrica horaria"
      ],
      "Include day metric": [
        "Incluir métrica diaria"
      ],
      "Include month metric": [
        "Incluir métrica mensual"
      ],
      "Include year metric": [
        "Incluir métrica anual"
      ],
      "Include table header": [
        "Incluir encabezado de tabla"
      ],
      "Add previous metric for compare": [
        "Agregar métrica previa para comparar"
      ],
      "Fail on first error": [
        "Fallar en el primer error"
      ],
      "Download": [
        "Descargar"
      ],
      "downloading... %1$s": [
        "descargando... %1$s"
      ],
      "Download completed": [
        "Descarga completada"
      ],
      "Click here to save the file in your computer.": [
        "click aquí para guardar el archivo en su computadora"
      ],
      "Can't delete the account": [
        "No se puede eliminar la cuenta"
      ],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.": [
        "La cuenta no puede ser eliminada mientras tiene saldo. Primero aseguresé que el dueño haga un egreso completo."
      ],
      "Account removed": [
        "Cuenta eliminada"
      ],
      "No enough permission to delete the account.": [
        "No tiene permisos suficientes para eliminar la cuenta."
      ],
      "The username was not found.": [
        "El nombr ede usuario no se encontró."
      ],
      "Can't delete a reserved username.": [
        "No se puede eliminar un nombre de usuario reservado."
      ],
      "Can't delete an account with balance different than zero.": [
        "No se puede eliminar una cuenta con saldo diferente a cero."
      ],
      "Name doesn't match": [
        "el nombre no coincide"
      ],
      "You are going to remove the account": [
        "Está por eliminar la cuenta"
      ],
      "This step can't be undone.": [
        "Este paso no puede ser deshecho."
      ],
      "Deleting account \"%1$s\"": [
        "Borrando cuenta \"%1$s\""
      ],
      "Verification": [
        "Verificación"
      ],
      "Enter the account name that is going to be deleted": [
        "ingrese el nombre de cuenta que será eliminado"
      ],
      "loading...": [
        "descargando... %1$s"
      ],
      "only admin can setup conversion": [
        "Solo los administradores del sistema pueden crear cuentas."
      ],
      "Wrong credentials": [
        "Credenciales incorrectas para \"%1$s\""
      ],
      "Conversion is disabled": [
        "Tasa de conversión"
      ],
      "Config cashout": [
        "Egreso"
      ],
      "Config cashin": [
        "Ingreso"
      ],
      "Cashin ratio": [
        "Ingreso"
      ],
      "Cashout ratio": [
        "Egreso creado"
      ],
      "Bad ratios": [
        ""
      ],
      "One of the ratios should be higher or equal than 1 an the other should be lower or equal than 1.": [
        ""
      ],
      "Initial amount": [
        "Monto máximo de extracción"
      ],
      "Use it to test how the conversion will affect the amount.": [
        ""
      ],
      "Sending to this bank": [
        ""
      ],
      "Converted": [
        "Tasa de conversión"
      ],
      "Cashin after fee": [
        ""
      ],
      "Sending from this bank": [
        ""
      ],
      "Cashout after fee": [
        "Egreso creado"
      ],
      "Bad configuration": [
        ""
      ],
      "This configuration allows users to cash out more of what has been cashed in.": [
        ""
      ],
      "Rnvalid": [
        "inválido"
      ],
      "Minimum amount": [
        ""
      ],
      "Only cashout operation above this threshold will be allowed": [
        "La operación de egreso ya está abortada."
      ],
      "Ratio": [
        ""
      ],
      "Conversion ratio between currencies": [
        "Tasa de conversión"
      ],
      "Example conversion": [
        ""
      ],
      "1 %1$s will be converted into %2$s %3$s": [
        ""
      ],
      "Rounding value": [
        ""
      ],
      "Smallest difference between two amounts after the ratio is applied.": [
        ""
      ],
      "Rounding mode": [
        ""
      ],
      "Zero": [
        ""
      ],
      "Amount will be round below to the largest possible value smaller than the input.": [
        ""
      ],
      "Up": [
        ""
      ],
      "Amount will be round up to the smallest possible value larger than the input.": [
        ""
      ],
      "Nearest": [
        ""
      ],
      "Amount will be round to the closest possible value.": [
        ""
      ],
      "Examples": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.1": [
        ""
      ],
      "Given the rounding value of 0.1 the possible values closest to 1.24 are: 1.1, 1.2, 1.3, 1.4.": [
        ""
      ],
      "With the \"zero\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.1": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.3": [
        ""
      ],
      "Given the rounding value of 0.3 the possible values closest to 1.24 are: 0.9, 1.2, 1.5, 1.8.": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.5": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.3": [
        ""
      ],
      "Amount to be deducted before amount is credited.": [
        ""
      ],
      "Cashout id should be a number": [
        "debería ser un correo electrónico"
      ],
      "This cashout not found. Maybe already aborted.": [
        "Este egreso no se encontró. Quizá fue abortado."
      ],
      "Cashout detail": [
        "Detalles de egreso"
      ],
      "Debited": [
        "Debitado"
      ],
      "Credited": [
        "Acreditado"
      ],
      "Welcome to %1$s!": [
        "Bienvenido a %1$s!"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "es",
  "completeness": 79
};

strings['de'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "de"
      },
      "Show withdrawal confirmation": [
        "Zeige Bestätigung der Abhebung"
      ],
      "Withdraw without setting amount": [
        ""
      ],
      "Show copy account letter": [
        ""
      ],
      "Show demo description": [
        "Demobeschreibung anzeigen"
      ],
      "Show install wallet first": [
        "Zeige Bestätigung der Abhebung"
      ],
      "Remove password length validation on registration": [
        ""
      ],
      "Show debug info": [
        "Debug-Informationen anzeigen"
      ],
      "Operation failed, please report": [
        "Vorgang fehlgeschlagen, bitte Fehler melden"
      ],
      "Request timeout": [
        "Zeitüberschreitung der Anforderung"
      ],
      "Request throttled": [
        "Anfrage verzögert sich"
      ],
      "Malformed response": [
        "Unstimmige Antwort"
      ],
      "Network error": [
        "Netzwerkfehler"
      ],
      "Unexpected request error": [
        "Unerwarteter Fehler bei der Anforderung"
      ],
      "Unexpected error": [
        "Unerwarteter Fehler"
      ],
      "An IBAN consists of capital letters and numbers only": [
        "IBAN sollte nur Großbuchstaben und Zahlen enthalten"
      ],
      "IBAN numbers have more that 4 digits": [
        "Eine IBAN besteht normalerweise aus mehr als 4 Ziffern"
      ],
      "IBAN numbers have less that 34 digits": [
        "Eine IBAN besteht normalerweise aus weniger als 34 Ziffern"
      ],
      "IBAN country code not found": [
        "IBAN-Ländercode wurde nicht gefunden"
      ],
      "IBAN number is not valid, checksum is wrong": [
        "IBAN-Nummer ist ungültig, die Prüfsumme ist falsch"
      ],
      "Use letters, numbers or any of these characters: - . _ ~": [
        ""
      ],
      "Required": [
        "Erforderlich"
      ],
      "Not valid": [
        "nicht gültig"
      ],
      "Does not follow the pattern": [
        "Weicht vom Muster ab"
      ],
      "The request was invalid or the payto://-URI used unacceptable features.": [
        "Die Anfrage war ungültig oder die payto://-URI nutzte inakzeptable Merkmale."
      ],
      "Not enough permission to complete the operation.": [
        "Nicht genug Berechtigungen, um den Vorgang abzuschließen."
      ],
      "The bank administrator cannot be the transfer creditor.": [
        ""
      ],
      "The destination account \"%1$s\" was not found.": [
        "Das Zielkonto \"%1$s\" wurde nicht gefunden."
      ],
      "The origin and the destination of the transfer can't be the same.": [
        "Ursprung und Ziel des Transfers können nicht gleich sein."
      ],
      "Your balance is not sufficient for the operation.": [
        "Der Saldo ist nicht ausreichend."
      ],
      "The origin account \"%1$s\" was not found.": [
        "Das Ursprungskonto \"%1$s\" wurde nicht gefunden."
      ],
      "The attempt to create the transaction has failed. Please try again.": [
        ""
      ],
      "The wire transfer was successfully completed!": [
        "Banküberweisung abgeschlossen!"
      ],
      "Input wire transfer detail": [
        "Geben Sie hier die Überweisungsdetails ein"
      ],
      "Using a form": [
        "Mithilfe eines Formulars"
      ],
      "A special URI that specifies the amount to be transferred and the destination account.": [
        ""
      ],
      "QR code": [
        ""
      ],
      "If your device has a camera, you can import a payto:// URI from a QR code.": [
        ""
      ],
      "Cashout": [
        ""
      ],
      "Recipient": [
        "Empfängerkonto"
      ],
      "ID of the recipient's account": [
        "ID des Empfängerkontos"
      ],
      "username": [
        "Benutzername"
      ],
      "IBAN of the recipient's account": [
        "IBAN des Empfängerkontos"
      ],
      "Transfer subject": [
        "Buchungsvermerk der Überweisung"
      ],
      "Subject": [
        "Buchungsvermerk"
      ],
      "Some text to identify the transfer": [
        "Etwas Text, um den Transfer zu identifizieren"
      ],
      "Amount": [
        "Betrag"
      ],
      "Amount to transfer": [
        "Zu überweisender Betrag"
      ],
      "Payto URI:": [
        "payto URI:"
      ],
      "Uniform resource identifier of the target account": [
        ""
      ],
      "payto://x-taler-bank/[bank-host]/[receiver-account]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[receiver-iban]?message=[Buchungsvermerk]&amount=[%1$s:X.Y]"
      ],
      "payto://iban/[receiver-iban]?message=[subject]&amount=[%1$s:X.Y]": [
        "payto://iban/[receiver-iban]?message=[Buchungsvermerk]&amount=[%1$s:X.Y]"
      ],
      "The maximum amount for a wire transfer is %1$s": [
        ""
      ],
      "Cost": [
        ""
      ],
      "Cancel": [
        "Abbrechen"
      ],
      "Send": [
        ""
      ],
      "The target type is unknown, use \"%1$s\"": [
        ""
      ],
      "Only \"x-taler-bank\" target are supported": [
        "Nur \"IBAN\" Ziele werden unterstützt"
      ],
      "Only this host is allowed. Use \"%1$s\"": [
        ""
      ],
      "Account name is missing": [
        "Konten"
      ],
      "Only \"IBAN\" target are supported": [
        "Nur \"IBAN\" Ziele werden unterstützt"
      ],
      "Missing \"amount\" parameter to specify the amount to be transferred": [
        "Nutze den Parameter \"amount\" um den zu sendenden Betrag anzugeben"
      ],
      "The \"amount\" parameter is not valid": [
        "der Betrag ist nicht gültig"
      ],
      "\"message\" parameters to specify a reference text for the transfer are missing": [
        "Nutze den Parameter \"message\", um einen Referenztext für den Transfer anzugeben"
      ],
      "The only currency allowed is \"%1$s\"": [
        ""
      ],
      "You cannot transfer an amount of zero.": [
        ""
      ],
      "The balance is not sufficient": [
        "Das Guthaben ist nicht ausreichend"
      ],
      "Please enter a longer subject": [
        "Buchungsvermerk der Überweisung"
      ],
      "Currently, the bank is not accepting new registrations!": [
        ""
      ],
      "The name is missing": [
        ""
      ],
      "Missing username": [
        "Fehlender Benutzername"
      ],
      "Missing password": [
        "Fehlendes Passwort"
      ],
      "The password should be longer than 8 letters": [
        "sollte größer als 0 sein"
      ],
      "The passwords do not match": [
        ""
      ],
      "Server replied with invalid phone or email.": [
        ""
      ],
      "You are not authorised to create this account.": [
        ""
      ],
      "Registration is disabled because the bank ran out of bonus credit.": [
        ""
      ],
      "That username can't be used because is reserved.": [
        ""
      ],
      "That username is already taken.": [
        ""
      ],
      "That account ID is already taken.": [
        ""
      ],
      "No information for the selected authentication channel.": [
        ""
      ],
      "Authentication channel is not supported.": [
        ""
      ],
      "Only an administrator is allowed to set the debt limit.": [
        ""
      ],
      "Only the administrator can change the minimum cashout limit.": [
        ""
      ],
      "Only admin can create accounts with second factor authentication.": [
        ""
      ],
      "The password is too short.": [
        ""
      ],
      "The password is too long.": [
        ""
      ],
      "Account registration": [
        ""
      ],
      "Login username": [
        "Fehlender Benutzername"
      ],
      "Password": [
        "Passwort"
      ],
      "Use a strong password: 8 characters minimum, don't use any public information related to you (names, birthday, phone number, etc...) and mix lowercase, uppercase, symbols and numbers": [
        ""
      ],
      "Repeat password": [
        ""
      ],
      "Full name": [
        ""
      ],
      "Register": [
        "Registrieren"
      ],
      "Create a random temporary user": [
        ""
      ],
      "Wrong credentials for \"%1$s\"": [
        "Falsche Zugangsdaten für \"%1$s\""
      ],
      "Account not found": [
        "Konto nicht gefunden"
      ],
      "Username": [
        "Benutzername"
      ],
      "Username of the account": [
        "Benutzername des Kontos"
      ],
      "Password of the account": [
        "Passwort des Kontos"
      ],
      "Check": [
        "Überprüfung"
      ],
      "Log in": [
        "Anmelden"
      ],
      "Transactions history": [
        ""
      ],
      "No transactions yet.": [
        "Es liegen noch keine Transaktionen vor."
      ],
      "You can make a transfer or a withdrawal to your wallet.": [
        ""
      ],
      "Date": [
        "Datum"
      ],
      "Counterpart": [
        "Gegenkonto"
      ],
      "sent": [
        "gesendet"
      ],
      "received": [
        "empfangen"
      ],
      "Invalid value": [
        "ungültiger Wert"
      ],
      "to": [
        "an"
      ],
      "from": [
        "von"
      ],
      "First page": [
        "Erste Seite"
      ],
      "Next": [
        "Nächste"
      ],
      "Wire transfer completed!": [
        "Banküberweisung abgeschlossen!"
      ],
      "The withdrawal has been aborted previously and can't be confirmed": [
        ""
      ],
      "The withdrawal operation can't be confirmed before a wallet accepted the transaction.": [
        ""
      ],
      "The operation ID is invalid.": [
        "Die Vorgangs-ID ist ungültig."
      ],
      "The operation was not found.": [
        ""
      ],
      "The starting withdrawal amount and the confirmation amount differs.": [
        ""
      ],
      "The bank requires a bank account which has not been specified yet.": [
        ""
      ],
      "The reserve operation has been confirmed previously and can't be aborted": [
        ""
      ],
      "Confirm the withdrawal operation": [
        "Bestätigen Sie den Abhebevorgang"
      ],
      "Wire transfer details": [
        ""
      ],
      "Payment Service Provider's account": [
        ""
      ],
      "Payment Service Provider's account number": [
        ""
      ],
      "Payment Service Provider's name": [
        ""
      ],
      "Payment Service Provider's account bank hostname": [
        ""
      ],
      "Payment Service Provider's account id": [
        ""
      ],
      "Payment Service Provider's account address": [
        ""
      ],
      "No amount has yet been determined.": [
        ""
      ],
      "Transfer": [
        "Überweisung"
      ],
      "Authentication required": [
        ""
      ],
      "This operation was created with another username": [
        ""
      ],
      "Unauthorized to make the operation, maybe the session has expired or the password changed.": [
        ""
      ],
      "The operation was rejected due to insufficient funds.": [
        ""
      ],
      "Withdrawal confirmed": [
        ""
      ],
      "The wire transfer to the Payment Service Provider has been initiated. You will shortly receive the requested amount in your Taler wallet.": [
        ""
      ],
      "Do not show this again": [
        ""
      ],
      "Close": [
        "Schließen"
      ],
      "If you have a Taler wallet installed on this device": [
        ""
      ],
      "Your wallet will display the details of the transaction including the fees (if applicable). If you do not yet have a wallet, please follow the instructions": [
        ""
      ],
      "on this page": [
        "Erste Seite"
      ],
      "Withdraw": [
        "Abheben"
      ],
      "In case you have a Taler wallet on another device": [
        ""
      ],
      "Scan the QR below to start the withdrawal.": [
        ""
      ],
      "There is an operation already pending": [
        ""
      ],
      "Complete the operation in": [
        "Abschließen oder Abbruch in"
      ],
      "this page": [
        ""
      ],
      "Invalid": [
        "Ungültig"
      ],
      "Balance is not enough": [
        "Der Saldo ist nicht ausreichend"
      ],
      "The server replied with an invalid taler://withdraw URI": [
        ""
      ],
      "Withdraw URI: %1$s": [
        ""
      ],
      "The operation was rejected due to insufficient funds": [
        ""
      ],
      "Current balance is %1$s": [
        ""
      ],
      "You can withdraw up to %1$s": [
        ""
      ],
      "Continue": [
        "Weiter"
      ],
      "Use your Taler wallet": [
        ""
      ],
      "After using your wallet you will need to authorize or cancel the operation on this site.": [
        ""
      ],
      "You need a Taler wallet": [
        ""
      ],
      "If you don't have one yet you can follow the instruction in": [
        ""
      ],
      "Send money": [
        ""
      ],
      "to a Taler wallet": [
        ""
      ],
      "Withdraw digital money into your mobile wallet or browser extension": [
        ""
      ],
      "to another bank account": [
        ""
      ],
      "Make a wire transfer to an account with known bank account number.": [
        ""
      ],
      "This is a demo": [
        ""
      ],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s .": [
        ""
      ],
      "Here you will be able to see how a bank that supports Taler directly would work.": [
        ""
      ],
      "Pending account delete operation": [
        ""
      ],
      "Pending account update operation": [
        ""
      ],
      "Pending password update operation": [
        ""
      ],
      "Pending transaction operation": [
        ""
      ],
      "Pending withdrawal operation": [
        ""
      ],
      "Pending cashout operation": [
        ""
      ],
      "You can complete or cancel the operation in": [
        ""
      ],
      "Internal error, please report.": [
        ""
      ],
      "Preferences": [
        ""
      ],
      "Welcome": [
        ""
      ],
      "Welcome, %1$s": [
        ""
      ],
      "History of public accounts": [
        "Buchungen auf öffentlich sichtbaren Konten"
      ],
      "Scan the QR code below to start the withdrawal.": [
        ""
      ],
      "Operation aborted": [
        ""
      ],
      "The wire transfer to the Payment Service Provider's account was aborted from somewhere else, your balance was not affected.": [
        ""
      ],
      "Go to your wallet now": [
        ""
      ],
      "The operation is marked as selected, but a process during the withdrawal failed": [
        ""
      ],
      "The account was selected, but no withdrawal reserve ID was found.": [
        ""
      ],
      "There is a withdrawal reserve ID but no account has been selected or the selected account is invalid.": [
        ""
      ],
      "A withdrawal reserve ID was not found and the no account has been selected.": [
        ""
      ],
      "Operation not found": [
        ""
      ],
      "This process is not known to the server. The process ID is incorrect or the server has deleted the process information before it arrived here.": [
        ""
      ],
      "Continue to dashboard": [
        ""
      ],
      "Confirmation codes are numerical, possibly beginning with 'T-.'": [
        ""
      ],
      "No cashout was found. The cashout process has probably already been aborted.": [
        ""
      ],
      "Challenge not found.": [
        ""
      ],
      "This user is not authorized to complete this challenge.": [
        ""
      ],
      "Too many attempts, try another code.": [
        ""
      ],
      "The confirmation code is wrong, try again.": [
        ""
      ],
      "The operation expired.": [
        ""
      ],
      "The operation failed.": [
        ""
      ],
      "The operation needs another confirmation to complete.": [
        ""
      ],
      "Confirm the operation": [
        "Vorgang bestätigen"
      ],
      "This operation is protected with second factor authentication. In order to complete it we need to verify your identity using the authentication channel you provided.": [
        ""
      ],
      "Enter the confirmation code": [
        ""
      ],
      "You should have received a code on your mobile phone.": [
        ""
      ],
      "You should have received a code in your email.": [
        ""
      ],
      "The confirmation code starts with \"%1$s\" followed by numbers.": [
        ""
      ],
      "Confirm": [
        "Bestätigen"
      ],
      "Removing account": [
        ""
      ],
      "Updating account values": [
        "Kontostand"
      ],
      "Updating password": [
        "Fehlendes Passwort"
      ],
      "Making a wire transfer": [
        "Banküberweisung"
      ],
      "Confirming withdrawal": [
        "Abhebung bestätigen"
      ],
      "Making a cashout": [
        ""
      ],
      "Operation:": [
        ""
      ],
      "Type": [
        "Typ"
      ],
      "Updating account settings": [
        ""
      ],
      "Account": [
        "Konten"
      ],
      "To account": [
        ""
      ],
      "Cashout account": [
        "Passwort des Kontos"
      ],
      "Email": [
        "E-Mail"
      ],
      "Phone": [
        ""
      ],
      "Debit threshold": [
        ""
      ],
      "Is this account public?": [
        ""
      ],
      "Enable": [
        ""
      ],
      "Disable": [
        ""
      ],
      "Name": [
        "Name"
      ],
      "Authentication channel": [
        ""
      ],
      "Remove": [
        "Entfernen"
      ],
      "New password": [
        ""
      ],
      "Challenge details": [
        ""
      ],
      "Sent at": [
        ""
      ],
      "To phone": [
        ""
      ],
      "To email": [
        ""
      ],
      "Send again": [
        ""
      ],
      "Withdraw reserve ID": [
        "Abheben"
      ],
      "Cashout is disabled": [
        ""
      ],
      "Cashout should be enabled in the configuration, the conversion rate should be initialized with fee(s), rates and a rounding mode.": [
        ""
      ],
      "Make a wire transfer": [
        ""
      ],
      "The Withdrawal URI is not valid": [
        ""
      ],
      "Cashout should be enable by configuration and the conversion rate should be initialized with fee, ratio and rounding mode.": [
        ""
      ],
      "Latest cashouts": [
        ""
      ],
      "Created": [
        ""
      ],
      "Total debit": [
        ""
      ],
      "Total credit": [
        ""
      ],
      "Select a section": [
        ""
      ],
      "Details": [
        ""
      ],
      "Delete": [
        "Löschen"
      ],
      "Credentials": [
        "Anmeldedaten"
      ],
      "Cashouts": [
        "Auszahlungen (Cashout)"
      ],
      "Conversion": [
        ""
      ],
      "Unable to create a cashout": [
        ""
      ],
      "The bank configuration does not support cashout operations.": [
        ""
      ],
      "Amount needs to be higher": [
        ""
      ],
      "It is not possible to cashout less than %1$s": [
        ""
      ],
      "Your account can't cashout less than %1$s": [
        ""
      ],
      "The total transfer to the destination will be zero": [
        ""
      ],
      "Cashout created": [
        ""
      ],
      "Duplicated request detected, check if the operation succeeded or try again.": [
        ""
      ],
      "The conversion rate was applied incorrectly": [
        ""
      ],
      "The account does not have sufficient funds": [
        ""
      ],
      "Missing cashout URI in the profile": [
        ""
      ],
      "The amount is below the minimum amount permitted.": [
        ""
      ],
      "Sending the confirmation message failed, retry later or contact the administrator.": [
        ""
      ],
      "The server doesn't support the current TAN channel.": [
        ""
      ],
      "Conversion rate": [
        ""
      ],
      "Balance": [
        "Saldo"
      ],
      "Fee": [
        ""
      ],
      "Legal name": [
        ""
      ],
      "If this name doesn't match the account holder's name, your transaction may fail.": [
        ""
      ],
      "No cashout account": [
        ""
      ],
      "Before being able to cashout to a bank account, you need to complete your profile": [
        ""
      ],
      "Currency": [
        ""
      ],
      "Send %1$s": [
        ""
      ],
      "Receive %1$s": [
        "%1$s empfangen"
      ],
      "Total cost": [
        ""
      ],
      "Balance left": [
        ""
      ],
      "Before fee": [
        ""
      ],
      "Total cashout transfer": [
        ""
      ],
      "Cashout for account %1$s": [
        ""
      ],
      "Doesn't have the pattern of an email": [
        ""
      ],
      "Should start with +": [
        ""
      ],
      "A phone number consists of numbers only": [
        ""
      ],
      "Account ID for authentication": [
        ""
      ],
      "Name of the account holder": [
        "Benutzername des Kontos"
      ],
      "Internal account": [
        "Benutzername des Kontos"
      ],
      "If this field is empty, a random account ID will be assigned": [
        ""
      ],
      "You can copy and share this IBAN number in order to receive wire transfers to your bank account": [
        ""
      ],
      "To be used when second factor authentication is enabled": [
        ""
      ],
      "External account number where the money is going to be sent when doing cashouts": [
        ""
      ],
      "Max debt": [
        ""
      ],
      "How much the balance can go below zero.": [
        ""
      ],
      "Minimum cashout": [
        ""
      ],
      "Custom minimum cashout amount for this account.": [
        ""
      ],
      "Public accounts have their balance publicly accessible": [
        ""
      ],
      "Does this account belong to a Payment Service Provider?": [
        ""
      ],
      "Account updated": [
        ""
      ],
      "The rights to change the account are not sufficient": [
        ""
      ],
      "The username was not found": [
        ""
      ],
      "You can't change the legal name, please contact the your account administrator.": [
        ""
      ],
      "You can't change the debt limit, please contact the your account administrator.": [
        ""
      ],
      "You can't change the cashout address, please contact the your account administrator.": [
        ""
      ],
      "Account \"%1$s\"": [
        ""
      ],
      "Removed": [
        ""
      ],
      "This account can't be used.": [
        ""
      ],
      "Change details": [
        ""
      ],
      "Update": [
        "Aktualisieren"
      ],
      "Merchant integration": [
        ""
      ],
      "Use this information to link your Taler Merchant Backoffice account with the current bank account. You can start by copying the values, then go to your merchant backoffice service provider, login into your account and look for the \"import\" button in the \"bank account\" section.": [
        ""
      ],
      "Account type": [
        "Konten"
      ],
      "Method to use for wire transfer.": [
        ""
      ],
      "IBAN": [
        "IBAN"
      ],
      "International Bank Account Number.": [
        "IBAN (Internationale Bankkontonummer)."
      ],
      "Account name": [
        "Kontenbezeichnung (Name des Kontoinhabers)"
      ],
      "Bank host where the service is located.": [
        ""
      ],
      "Bank account identifier for wire transfers.": [
        ""
      ],
      "Address": [
        "Adresse"
      ],
      "Owner's name": [
        "Name des Kontoinhabers"
      ],
      "Legal name of the person holding the account.": [
        "Rechtsgültiger Name des Kontoinhabers."
      ],
      "Account info URL": [
        "URL für Kontoinformationen"
      ],
      "From where the merchant can download information about incoming wire transfers to this account.": [
        "Von wo der Händler Informationen über eingehende Überweisungen auf dieses Konto herunterladen kann."
      ],
      "Copy": [
        "Kopieren"
      ],
      "Repeated password doesn't match": [
        ""
      ],
      "Password changed": [
        ""
      ],
      "Not authorized to change the password, maybe the session is invalid.": [
        ""
      ],
      "You need to provide the old password. If you don't have it contact your account administrator.": [
        ""
      ],
      "Your current password doesn't match, can't change to a new password.": [
        ""
      ],
      "Update password": [
        ""
      ],
      "Current password": [
        ""
      ],
      "Your current password, for security": [
        ""
      ],
      "Type it again": [
        ""
      ],
      "Repeat the same password": [
        ""
      ],
      "Change": [
        ""
      ],
      "Accounts": [
        "Konten"
      ],
      "Create account": [
        ""
      ],
      "Actions": [
        ""
      ],
      "Unknown": [
        "unbekannt"
      ],
      "Change password": [
        "Fehlendes Passwort"
      ],
      "Querying for the current stats failed": [
        ""
      ],
      "The request parameters are wrong": [
        ""
      ],
      "The user is unauthorized": [
        ""
      ],
      "Querying for the previous stats failed": [
        ""
      ],
      "Transaction volume report": [
        ""
      ],
      "Last hour": [
        ""
      ],
      "Previous day": [
        ""
      ],
      "Last month": [
        ""
      ],
      "Last year": [
        ""
      ],
      "Last Year": [
        ""
      ],
      "Trading volume from %1$s to %2$s": [
        ""
      ],
      "Cashin": [
        ""
      ],
      "Transferred from an external account to an account in this bank.": [
        ""
      ],
      "Transferred from an account in this bank to an external account.": [
        ""
      ],
      "Payin": [
        ""
      ],
      "Transferred from an account to a Taler exchange.": [
        ""
      ],
      "Payout": [
        ""
      ],
      "Transferred from a Taler exchange to another account.": [
        ""
      ],
      "Download stats as CSV": [
        ""
      ],
      "previous": [
        ""
      ],
      "Decreased by": [
        ""
      ],
      "Increased by": [
        ""
      ],
      "Account created with password \"%1$s\".": [
        ""
      ],
      "Server replied that phone or email is invalid": [
        ""
      ],
      "The rights to perform the operation are not sufficient": [
        ""
      ],
      "Account username is already taken": [
        ""
      ],
      "Account id is already taken": [
        ""
      ],
      "Bank ran out of bonus credit.": [
        ""
      ],
      "Account username can't be used because is reserved": [
        ""
      ],
      "Can't create accounts": [
        ""
      ],
      "Only system admin can create accounts.": [
        ""
      ],
      "New bank account": [
        "Kontostand"
      ],
      "Create": [
        ""
      ],
      "Download bank stats": [
        ""
      ],
      "Include hour metric": [
        ""
      ],
      "Include day metric": [
        ""
      ],
      "Include month metric": [
        ""
      ],
      "Include year metric": [
        ""
      ],
      "Include table header": [
        ""
      ],
      "Add previous metric for compare": [
        ""
      ],
      "Fail on first error": [
        ""
      ],
      "Download": [
        ""
      ],
      "downloading... %1$s": [
        ""
      ],
      "Download completed": [
        ""
      ],
      "Click here to save the file in your computer.": [
        ""
      ],
      "Can't delete the account": [
        ""
      ],
      "The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.": [
        ""
      ],
      "Account removed": [
        ""
      ],
      "No enough permission to delete the account.": [
        ""
      ],
      "The username was not found.": [
        ""
      ],
      "Can't delete a reserved username.": [
        ""
      ],
      "Can't delete an account with balance different than zero.": [
        ""
      ],
      "Name doesn't match": [
        ""
      ],
      "You are going to remove the account": [
        ""
      ],
      "This step can't be undone.": [
        ""
      ],
      "Deleting account \"%1$s\"": [
        ""
      ],
      "Verification": [
        ""
      ],
      "Enter the account name that is going to be deleted": [
        ""
      ],
      "loading...": [
        ""
      ],
      "only admin can setup conversion": [
        ""
      ],
      "Wrong credentials": [
        "Falsche Zugangsdaten"
      ],
      "Conversion is disabled": [
        ""
      ],
      "Config cashout": [
        ""
      ],
      "Config cashin": [
        ""
      ],
      "Cashin ratio": [
        ""
      ],
      "Cashout ratio": [
        ""
      ],
      "Bad ratios": [
        ""
      ],
      "One of the ratios should be higher or equal than 1 an the other should be lower or equal than 1.": [
        ""
      ],
      "Initial amount": [
        "Höchste Abhebesumme"
      ],
      "Use it to test how the conversion will affect the amount.": [
        ""
      ],
      "Sending to this bank": [
        ""
      ],
      "Converted": [
        ""
      ],
      "Cashin after fee": [
        ""
      ],
      "Sending from this bank": [
        ""
      ],
      "Cashout after fee": [
        ""
      ],
      "Bad configuration": [
        ""
      ],
      "This configuration allows users to cash out more of what has been cashed in.": [
        ""
      ],
      "Rnvalid": [
        "nicht gültig"
      ],
      "Minimum amount": [
        ""
      ],
      "Only cashout operation above this threshold will be allowed": [
        ""
      ],
      "Ratio": [
        ""
      ],
      "Conversion ratio between currencies": [
        ""
      ],
      "Example conversion": [
        ""
      ],
      "1 %1$s will be converted into %2$s %3$s": [
        ""
      ],
      "Rounding value": [
        ""
      ],
      "Smallest difference between two amounts after the ratio is applied.": [
        ""
      ],
      "Rounding mode": [
        ""
      ],
      "Zero": [
        ""
      ],
      "Amount will be round below to the largest possible value smaller than the input.": [
        ""
      ],
      "Up": [
        ""
      ],
      "Amount will be round up to the smallest possible value larger than the input.": [
        ""
      ],
      "Nearest": [
        ""
      ],
      "Amount will be round to the closest possible value.": [
        ""
      ],
      "Examples": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.1": [
        ""
      ],
      "Given the rounding value of 0.1 the possible values closest to 1.24 are: 1.1, 1.2, 1.3, 1.4.": [
        ""
      ],
      "With the \"zero\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.2": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.1": [
        ""
      ],
      "With the \"nearest\" mode the value will be rounded to 1.3": [
        ""
      ],
      "Rounding an amount of 1.24 with rounding value 0.3": [
        ""
      ],
      "Given the rounding value of 0.3 the possible values closest to 1.24 are: 0.9, 1.2, 1.5, 1.8.": [
        ""
      ],
      "With the \"up\" mode the value will be rounded to 1.5": [
        ""
      ],
      "Rounding an amount of 1.26 with rounding value 0.3": [
        ""
      ],
      "Amount to be deducted before amount is credited.": [
        ""
      ],
      "Cashout id should be a number": [
        ""
      ],
      "This cashout not found. Maybe already aborted.": [
        ""
      ],
      "Cashout detail": [
        ""
      ],
      "Debited": [
        ""
      ],
      "Credited": [
        ""
      ],
      "Welcome to %1$s!": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "de",
  "completeness": 27
};

